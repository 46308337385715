/* OpenSans */
@font-face {
  font-family: 'OpenSans';
  src: url('~core/fonts/OpenSans/OpenSans-Light-webfont.eot');
  src:
    url('~core/fonts/OpenSans/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('~core/fonts/OpenSans/OpenSans-Light-webfont.woff') format('woff'),
    url('~core/fonts/OpenSans/OpenSans-Light-webfont.ttf') format('truetype'),
    url('~core/fonts/OpenSans/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('~core/fonts/OpenSans/OpenSans-Regular-webfont.eot');
  src:
    url('~core/fonts/OpenSans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('~core/fonts/OpenSans/OpenSans-Regular-webfont.woff') format('woff'),
    url('~core/fonts/OpenSans/OpenSans-Regular-webfont.ttf') format('truetype'),
    url('~core/fonts/OpenSans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('~core/fonts/OpenSans/OpenSans-Semibold-webfont.eot');
  src:
    url('~core/fonts/OpenSans/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('~core/fonts/OpenSans/OpenSans-Semibold-webfont.woff') format('woff'),
    url('~core/fonts/OpenSans/OpenSans-Semibold-webfont.ttf') format('truetype'),
    url('~core/fonts/OpenSans/OpenSans-Semibold-webfont.svg#open_sanslight') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('~core/fonts/OpenSans/OpenSans-Bold-webfont.eot');
  src:
    url('~core/fonts/OpenSans/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('~core/fonts/OpenSans/OpenSans-Bold-webfont.woff') format('woff'),
    url('~core/fonts/OpenSans/OpenSans-Bold-webfont.ttf') format('truetype'),
    url('~core/fonts/OpenSans/OpenSans-Bold-webfont.svg#open_sanslight') format('svg');
  font-weight: 700;
  font-style: normal;
}

/* Whitney */
@font-face {
  font-family: 'Whitney';
  src:
    url('../fonts/Whitney/WhitneySSm-Light_Web.woff2') format('woff2'),
    url('../fonts/Whitney/WhitneySSm-Light_Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney';
  src:
    url('../fonts/Whitney/WhitneySSm-LightItalic_Web.woff2') format('woff2'),
    url('../fonts/Whitney/WhitneySSm-LightItalic_Web.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Whitney';
  src:
    url('../fonts/Whitney/WhitneySSm-Book_Web.woff2') format('woff2'),
    url('../fonts/Whitney/WhitneySSm-Book_Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney';
  src:
    url('../fonts/Whitney/WhitneySSm-BookItalic_Web.woff2') format('woff2'),
    url('../fonts/Whitney/WhitneySSm-BookItalic_Web.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Whitney';
  src:
    url('../fonts/Whitney/WhitneySSm-Medium_Web.woff2') format('woff2'),
    url('../fonts/Whitney/WhitneySSm-Medium_Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney';
  src:
    url('../fonts/Whitney/WhitneySSm-MediumItalic_Web.woff2') format('woff2'),
    url('../fonts/Whitney/WhitneySSm-MediumItalic_Web.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Whitney';
  src:
    url('../fonts/Whitney/WhitneySSm-Semibold_Web.woff2') format('woff2'),
    url('../fonts/Whitney/WhitneySSm-Semibold_Web.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney';
  src:
    url('../fonts/Whitney/WhitneySSm-SemiboldItalic_Web.woff2') format('woff2'),
    url('../fonts/Whitney/WhitneySSm-SemiboldItalic_Web.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Whitney';
  src:
    url('../fonts/Whitney/WhitneySSm-Bold_Web.woff2') format('woff2'),
    url('../fonts/Whitney/WhitneySSm-Bold_Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney';
  src:
    url('../fonts/Whitney/WhitneySSm-BoldItalic_Web.woff2') format('woff2'),
    url('../fonts/Whitney/WhitneySSm-BoldItalic_Web.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Whitney';
  src:
    url('../fonts/Whitney/WhitneySSm-Black_Web.woff2') format('woff2'),
    url('../fonts/Whitney/WhitneySSm-Black_Web.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney';
  src:
    url('../fonts/Whitney/WhitneySSm-BlackItalic_Web.woff2') format('woff2'),
    url('../fonts/Whitney/WhitneySSm-BlackItalic_Web.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}
