@import '~core/styles/variables';

$xxlarge: 1420px;

/* Colors */
$brandBlue: #0b3a5f;
$brandDarkBlue: #02132d;
$brandDarkerBlue: #11172b;
$brandLightBlue: #4d90b8;
$brandRed: #c01933;
$brandWhite: #fff;
$brandBlack: #000;
$brandGray: #e6e6e6;
$secondaryBlue: #036;
$brandDarkGray: #333;

$linkColor: #68befb;
$errorColor: #f00;

$brandPrimaryColor: #212529;
$brandSecondaryColor: $secondaryBlue;

$brandBlue2: #0b2e79;
$brandBlue3: #069;
$brandBlue4: #4470c3;
$brandBlue5: #3d4558;
$brandBlue6: #242a48;
$brandBorder: #9da6ab;
$brandLightGray: #d8d8d8;
$brandRed2: #e01933;
$brandRed3: #e51937;
$brandRed4: #df1e33;
$brandGray2: #818181;
$brandGray3: #6e6e6e;
$brandGray4: #999;
$brandGray5: #ccc;
$brandGray6: #778194;
$brandGray7: #616779;
$brandLightBlue2: #1c7bd9;
$brandLightBlue3: #627a88;
$brandLightGray2: #e8ecf2;
$brandLightGray3: #f5f7fa;
$brandLightGray4: #f5f8fa;
$brandLightGray5: #f7f7f7;

/* TEMPORARY TO FIX BUILD */
$brandNeutralDarkColor2: $brandGray5;

/* Typography */
// Text
$brandFontFamily: 'Whitney', 'OpenSans', Helvetica, Arial, sans-serif;
$brandTextFontSize: (small: 1.3rem, medium: 1.3rem);
$brandTextColor: #3d4558;
$brandTextColorInverse: #fff;
$brandTextFontWeight: 400;
// Links
$brandLinkColor: $brandTextColor;
$brandLinkColorInverse: $linkColor;
$brandLinkColorHover: $brandBlue4;
// H1
$brandH1FontFamily: $brandFontFamily;
$brandH1Color: $brandDarkerBlue;
$brandH1FontSize: (small: 2.6rem, medium: 2.6rem);
$brandH1Transform: capitalize;
// H2
$brandH2FontFamily: $brandFontFamily;
$brandH2Color: $brandDarkerBlue;
$brandH2FontSize: (small: 3rem, medium: 3rem);
$brandH2FontWeight: 400;
$brandH2LineHeight: (small: 3.3rem, medium: 1.2);
$brandH2Margin: (small: 0 0 1rem);
$brandH2Transform: capitalize;
// H3
$brandH3FontFamily: $brandFontFamily;
$brandH3Color: $brandDarkerBlue;

// Border
$brandBorderColor: rgba(0, 0, 0, .2);

/* Buttons */
$brandButtonFontFamily: $brandFontFamily;
$brandButtonFontSize: 1.5rem;
$brandButtonLineHeight: 2.4rem;
$brandButtonLetterSpacing: 1.3px;
$brandButtonBackgroundColor: $brandRed2;
$brandButtonBackgroundGradient: none;
$brandButtonShadow: none;
$brandButtonBorder: none;
$brandButtonFontTransform: uppercase;
// Hover
$brandButtonColorHover: $brandWhite;
$brandButtonBackgroundGradientHover: none;
$brandButtonBackgroundColorHover: #b7142c;
$brandButtonBorderColorHover: ();
// Active
$brandButtonColorActive: $brandWhite;
$brandButtonBackgroundGradientActive: none;
$brandButtonBackgroundColorActive: #b7142c;
$brandButtonBorderColorActive: ();

/* Rebates */
// Rebate
$rebateColor: $brandBlue4;
$rebateValueColor: $brandBlue4;
$rebateValueWithCurrencyFontWeight: 600;
$rebateCurrencyColor: $brandBlue4;
$rebateCurrencyFontWeight: 600;
// Rebate V2
$rebateV2OldValueFontSize: 1.3rem;
$rebateV2OldValueColor: #3d4558;
$rebateV2OldValueLineHeight: 2.3rem;
$rebateV2NewValueFontColor: $brandBlue4;
$rebateV2NewValueFontSize: 1.3rem;
$rebateV2NewValueFontWeight: 600;
$rebateV2NewValueLineHeight: 2.3rem;
$rebateV2NewValueLetterSpacing: .46px;
$rebateV2NewValueTextTransform: uppercase;
$rebateV2TierNameTextTransform: none;

// Rebate Elevation
$rebateElevationColor: $brandTextColor;
$rebateElevationStrikethroughPrimary: 1px solid $brandWhite;
// Premier Rebate
$premierRebateOldValueAfterContent: '.';
$premierRebateElevationColor: #3d4558;
$premierRebateInlineTextTransform: uppercase;
$premierRebatePrefixFontsWeight: 600;
$premierRebateValueWithCurrencyOldValueTextTransform: lowercase;
$premierRebateValueWithCurrencyOldValueFirstLetterTextTransform: uppercase;
$premierRebateValueWithCurrencyOldValueDisplay: inline-block;
$premierRebateValueWithCurrencyLetterSpacing: .46px;
$premierRebateValueTierNameTextTransform: none;

/* Balloon tooltip */
$balloonTooltipBackground: rgba(17, 17, 17, .9);
$balloonTooltipSVGFillColor: '111111e6';
$balloonSVGFillColor: $balloonTooltipSVGFillColor;
$balloonSVGStrokeColor: $balloonTooltipSVGFillColor;
$balloon-arrow-margin-right: 2px;

/* Section Title */
$sectionTitleRowGap: (small: .5rem);
$sectionTitleH2FontSize: (small: 3rem, medium: 3rem);
$sectionTitleH2FontFamily: $brandFontFamily;
$sectionTitleH2FontWeight: (small: 300, medium: 400);
$sectionTitleH2LineHeight: (small: 3.3rem, medium: 1.2);
$sectionTitleH2TextAlign: (small: center, medium: center, xxlarge: left);
$sectionTitleAlignItems: (small: center, medium: center, xxlarge: flex-start);
$sectionTitleLinkFontSize: 1.5rem;
$sectionTitleLinkFontWeight: 600;
$sectionTitleLinkLetterSpacing: .34px;
$sectionTitleLinkTextTransform: capitalize;
$sectionTitleLinkColor: $brandPrimaryColor;
$sectionTitleLinkHoverTextDecoration: none;
$sectionTitleLinkAfterContent: '\f105';
$sectionTitleLinkAfterFontFamily: 'fontello';
$sectionTitleLinkAfterFontSize: 2.2rem;
$sectionTitleLinkAfterPositionTop: .2rem;
$sectionTitleLinkAfterMargin: 0 0 0 .8rem;
$sectionTitleLinkAfterColor: $brandRed3;
$sectionTitleLinkAfterFontWeight: 400;

/* Merchant Tile List */
$merchantTileListMarginBottom: 3rem;

/* Favorite Icon */
$favoriteIconFillColor: ();
$favoriteIconFavoritedFillColor: $brandRed3;

/* Favorites */
$brandUnselectedFavoriteOpacity: 0;

/* Marketing Motto */
$marketingMottoColor: $brandDarkerBlue;
$marketingMottoFontSize: 1.4rem;
$marketingMottoFontWeight: 700;
$marketingMottoLetterSpacing: 2.7px;
$marketingMottoTextTransform: uppercase;
$marketingMottoMargin: 0 0 .8rem 0;
$marketingMottoTextAlign: (small: center, xxlarge: left);

/* Search Form */
$searchFormFocusBorderColor: none;
$searchFieldPlaceholderColor: $brandGray6;
$searchFieldPlaceholderLetterSpacing: -.15rem;
$searchFieldPlaceholderFontSize: 300;
$searchFormSubmitButtonWidth: 4rem;

/* Recent Stores */
$recentStoresTitleFontWeight: 500;
$recentStoresTitleFontSize: 2rem;
$recentStoresTitleJustifyContent: center;
$recentStoresTitleHeadingTextTransform: capitalize;
$recentStoresPadding: 1.2rem 2rem;
$recentStoresFlipTileMargin: 0;
$recentStoresFlipTileItemBorderBottom: 1px solid $brandLightGray2;
$recentStoresFlipTileBackBackground: ();
$recentStoresViewAllLinkBottomFontWeight: 600;
$recentStoresViewAllLinkBottomMargin: 1rem 0 0;
$recentStoresViewAllLinkBottomFontSize: 1.3rem;
$recentStoresViewAllLinkBottomColor: $brandGray6;
$recentStoresViewAllLinkPadding: 0 1rem 0 0;
$recentStoresInSearchViewAllBottomLinkAfterContent: '\f105';
$recentStoresInSearchViewAllBottomLinkAfterFontFamily: 'fontello', serif;
$recentStoresInSearchViewAllBottomLinkAfterFontSize: 2.2rem;
$recentStoresInSearchViewAllBottomLinkAfterFontWeight: 400;
$recentStoresInSearchViewAllBottomLinkAfterColor: $brandRed3;
$recentStoresInSearchViewAllBottomLinkAfterLineHeight: 1;
$recentStoresInSearchViewAllBottomLinkAfterPosition: relative;
$recentStoresInSearchViewAllBottomLinkAfterBottom: -.2rem;
$recentStoresInSearchViewAllBottomLinkAfterRight: -.8rem;

/* Similar Stores */
$similarStoreDisplay: block;
$similarStoreBorder: 1px solid $brandLightGray2;
$similarStoreBorderRadius: 0;
$similarStoreBorderWidth: 0 0 1px;
$similarStoreFavoriteIconOrder: 3;
$similarStorePadding: 0;
$similarStoreJustifyContent: flex-start;
$similarStoreFavoriteIconMarginLeft: auto;
$similarStoreLogoMargin: 0 2rem 0 1rem;
$similarStoreRebatePadding: 0;
$similarStoreMargin: 0;
$similarStoresPadding: 1.2rem 2rem;
$similarStoresTitleFontWeight: 500;
$similarStoresTitleFontSize: 2rem;
$similarStoresTitleJustifyContent: center;
$similarStoresTitleHeadingTextTransform: capitalize;
$similarStoresRebateFontSize: 1.2rem;
$similarStoresRebateOldValueColor: $brandGray6;
$similarStoresRebateOldValueAfterContent: ',';
$similarStoreBackground: ();
$similarStoresInSearchItemBorder: (small: none, medium: solid $brandLightGray2);
$similarStoresInSearchItemBorderWidth: (small: (), medium: 0 0 1px);
$similarStoresFavoriteIconWidth: (small: 1.2rem, medium: 2.1rem);
$similarStoresFavoriteIconHeight: (small: 1.2rem, medium: 2.3rem);

/* Flip Tile */
$flipTileItemPadding: 0;
$flipTileLogoWrapMargin: 0 2rem 0 0;
$flipTileLogoWrapPadding: 0;
$flipTileBorder: solid #e8ecf2;
$flipTileBorderWidth: 0 0 1px;
$flipTileJustifyContent: flex-start;
$flipTilePremierRebatePadding: 0;
$flipTilePremierRebateTextAlign: left;
$flipTilePremierRebateColor: $brandGray6;
$flipTilePremierRebateTextTransform: uppercase;
$flipTilePremierRebateLetterSpacing: .46px;
$flipTilePremierRebateFontWeight: 600;
$flipTilePremierRebateFontSize: 1.2rem;
$flipTilePremierRebateValueWithCurrencyTextTransform: none;
$flipTilePremierRebateValueWithCurrencyColor: $brandWhite;
$flipTilePremierRebateValueWithCurrencyFontWeight: 600;
$flipTilePremierRebateValueColor: $brandGray6;
$flipTilePremierRebateElevationOldValueAfterContent: ',';
$flipTilePremierRebateInlineElevationNewValueFontWeight: 600;
$flipTilePremierRebateInlineElevationNewValueWhiteSpace: normal;
$flipTileButtonHeight: 2.7rem;
$flipTileButtonWidth: 10.4rem;
$flipTileButtonLineHeight: 2.7rem;
$flipTileButtonFontSize: 1.2rem;
$flipTileButtonBackground: $brandRed3;
$flipTileButtonMargin: 0;
$flipTileButtonHoverBackground: $brandButtonBackgroundColorHover;
$flipTileFavoriteIconDisplay: flex;
$flipTileFavoriteIconOrder: 3;
$flipTileFavoriteIconHeight: (small: 1.2rem, medium: 2.3rem);
$flipTileFavoriteIconWidth: (small: 1.2rem, medium: 2.1rem);
$flipTileFavoriteIconMargin: 0 0 0 auto;

/* Header navigation */
$navigationListItemFontSize: 1.5rem;
$navigationListItemLinkColor: $brandWhite;
$navigationListItemLinkPadding: 1.5rem 0;
$navigationListItemHoverTextDecoration: underline;
$navigationListDropdownItemTriggerColor: $brandWhite;
$navigationListDropdownItemTriggerPadding: 1.5rem 0;
$navigationListDropdownItemTriggerBeforeContent: '\f107';
$navigationListDropdownItemTriggerBeforeFontFamily: 'fontello', serif;
$navigationListDropdownItemTriggerBeforeFontWeight: bold;
$navigationListDropdownItemTriggerBeforeColor: $brandRed4;
$navigationListDropdownItemTriggerBeforeFontSize: 2.8rem;
$navigationListDropdownItemTriggerBeforePositionLeft: ();
$navigationListDropdownItemTriggerBeforePositionRight: 0;
$navigationListDropdownItemTriggerBeforePositionTop: 50%;
$navigationListDropdownItemTriggerFocusedBeforeTransform: rotate(180deg);
$navigationListDropdownItemMenuMargin: 0;
$navigationListDropdownItemMenuPadding: 0;
$navigationListDropdownItemMenuLinkPadding: 1rem;
$navigationListDropdownItemMenuLinkFontSize: 1.3rem;
$navigationListDropdownItemMenuLinkColor: $brandGray6;
$navigationListDropdownItemMenuLinkHoverTextDecoration: underline;
$navigationListDropdownItemMenuLinkTextTransform: capitalize;

$navPopularStoresViewAllLinkFontSize: 1.5rem;
$navPopularStoresViewAllLinkFontWeight: 600;

/* Nav Seasonal Flyout */
$navSeasonalFlyoutMargin: 3rem 0;
$navSeasonalFlyoutTileWidth: 13rem;
$navSeasonalFlyoutTileBorder: 1px solid $brandLightGray;
$navSeasonalFlyoutTileBorderRadius: 0;
$navSeasonalFlyoutTileMargin: 0 .5rem 1rem;
$navSeasonalFlyoutTileWrapperMargin: 0;
$navSeasonalFlyoutTilePillWidth: 8.8rem;
$navSeasonalFlyoutMerchantTileListJustifyContent: flex-start;
$navSeasonalFlyoutMerchantTileListMargin: 0 -.5rem;
$navSeasonalFlyoutCTAFontSize: 1.5rem;
$navSeasonalFlyoutCTAFontWeight: 600;
$navSeasonalFlyoutCTATextTransform: capitalize;
$navSeasonalFlyoutCTAWrapMargin: 1rem .5rem;
$navSeasonalFlyoutContainerJustifyContent: left;

/* Navigation banner */
$navBannerButtonBannerBackground: $brandWhite url('~org/assets/images/navBannerButton.png') no-repeat -4px -8px;
$navBannerFavoritesBannerBackground: $brandWhite url('~org/assets/images/navBannerFavorites.svg') no-repeat -4px -8px;
$navBannerButtonBannerImageBorder: $brandWhite solid 2px;
$navBannerButtonBannerImagePositionTop: -1.2rem;
$navBannerContentBackground: #3d4558;
$navBannerContentPadding: 1rem 2rem 1rem 8rem;
$navBannerLinkColor: $brandWhite;
$navBannerTitleFontSize: 1.5rem;
$navBannerTitleMargin: .5rem 0 .9rem;
$navBannerContentFontSize: 1.5rem;
$navBannerTitleTextTransform: uppercase;
$navBannerTitleColor: $brandWhite;
$navBannerTitleLetterSpacing: .4px;
$navBannerContentColor: $brandWhite;
$navBannerContentLetterSpacing: .4px;
$navBannerLinkFontWeight: 600;
$navBannerLinkTextDecoration: underline;
$navBannerTextLineHeight: 2.4rem;
$navBannerTextMargin: 0;

/* Navigation TOF */
$navCategoriesTOFDropdownMenuWidth: 36rem;
$navCategoriesTOFDropdownMenuPositionLeft: 25.9rem;
$navCategoriesTOFDropdownMenuPadding: 2rem 4rem;
$navCategoriesTOFDropdownMenuBackground: #282f45;
$navCategoriesTOFDropdownMenuTop: 1rem;
$navCategoriesTOFDropdownMenuMinHeight: calc(100% - 3rem);
$navCategoriesTOFFeaturedSectionTitleMargin: 0 0 1.5rem;
$navCategoryFeaturedItemTitleDisplay: inline;
$navCategoryFeaturedItemTitleFontSize: 1.3rem;
$navCategoryFeaturedItemTitleFontWeight: 400;
$navCategoryFeaturedItemTitleColor: $brandGray6;
$navCategoryFeaturedItemHoverColor: $brandWhite;
$navCategoryFeaturedItemHoverTextDecoration: underline;
$navCategoryFeaturedItemHoverColorBackground: none;
$navCategoryFeaturedItemRebateFontSize: 1.3rem;
$navCategoryFeaturedItemRebateDisplay: inline;
$navCategoryFeaturedItemInlineRebateDisplay: inline;
$navCategoryFeaturedItemInlineRebateFontSize: 1.3rem;
$navCategoryFeaturedItemRebateOldValueFontSize: 1.2rem;
$navCategoryFeaturedItemRebateOldValueColor: $brandGray6;
$navCategoryFeaturedItemRebateColor: $brandGray6;
$navCategoryFeaturedItemRebateTextTransform: uppercase;
$navCategoryFeaturedItemRebatePrefixColor: $brandGray6;
$navCategoryFeaturedItemRebatePrefixTextTransform: uppercase;
$navCategoryFeaturedItemRebatePrefixFontWeight: 600;
$navCategoryFeaturedFooterLinkFontSize: 1.5rem;
$navCategoryFeaturedFooterLinkMargin: 2rem 0 0;
$navCategoryFeaturedFooterLinkColor: $brandGray6;
$navCategoryFeaturedFooterLinkFontWeight: 600;
$navCategoryFeaturedFooterLinkLetterSpacing: .34px;
$navCategoryFeaturedFooterLinkHoverColor: $brandWhite;
$navCategoryFeaturedFooterLinkCategoryTextTransform: capitalize;

/* Category Flyout */
$categoryFlyoutTitleMargin: 0 0 1rem 0;
$categoryFlyoutTitleRebateMargin: ();
$categoryFlyoutTitleRebatePadding: ();
$categoryFlyoutTitleRebateBorder: ();
$categoryFlyoutTitleRebateBackground:();
$categoryFlyoutTitleRebateFontSize: 1.4rem;
$categoryFlyoutTitleRebateTextAlign: ();
$categoryFlyoutMerchantTitleFontSize: 1.4rem;
$categoryFlyoutMerchantTitleMargin: .6rem 0;
$categoryFlyoutTitleRebateNewValueFontSize: 1.3rem;
$categoryFlyoutMerchantTitleDisplay: inline;
$categoryFlyoutMerchantTitleColor: $brandGray6;
$categoryFlyoutMerchantTitleFontWeight: 700;
$categoryFlyoutInfoHoverColor: $brandWhite;
$categoryFlyoutInfoHoverColorTextDecoration: underline;
$categoryFlyoutTitleRebateDisplay: inline;
$categoryFlyoutTitleInlineRebateDisplay: inline;
$categoryFlyoutTitleRebateNewValueColor: $brandGray6;
$categoryFlyoutTitleRebateOldValueDisplay: inline;
$categoryFlyoutTitleRebateOldValueColor: $brandGray6;

/* Quick Search */
$quickSearchFooterProductLinkWrapMargin: 0;
$quickSearchFooterProductLinkWrapFontWeight: 600;
$quickSearchFooterProductLinkWrapPadding: 1rem 2rem;
$quickSearchFooterProductLinkColor: $brandGray6;
$quickSearchFooterProductLinkWrapBorderTop: 1px solid #9ea6a8;
$quickSearchFooterPadding: .5rem 4rem .5rem 2rem;
$quickSearchFooterLineHeight: 1.8rem;
$quickSearchFooterBackground: $brandWhite;
$quickSearchFooterColor: $brandTextColor;
$quickSearchFooterBorderRadius: 0;
$quickSearchItemsWrapperPadding: 1rem 0;
$quickSearchResultsOverflow: auto;
$quickSearchResultsMaxHeight: (small: none, medium: 21rem);
$quickSearchFavoriteIconHeight: (small: 1.2rem, medium: 2.3rem);
$quickSearchFavoriteIconWidth: (small: 1.2rem, medium: 2.1rem);
$quickSearchMatchColor: $brandGray6;
$quickSearchMatchFontSize: 1.3rem;
$quickSearchMatchHighlightColor: $brandGray6;
$quickSearchMerchantRebateFontSize: 1.3rem;
$quickSearchMerchantRebatePrefixFontWeight: 600;
$quickSearchMatchPadding: (small: .5rem 5rem .5rem 2rem, medium: .8rem 5rem .8rem 2.5rem);
$quickSearchMatchHoverBackground: #e7ecef;
$quickSearchMatchLineHeight: 2.2rem;
$quickSearchMerchantFavoriteIconPositionTop: (small: .8rem, medium: .8rem);
$quickSearchMerchantFavoriteIconPositionLeft: (small: (), medium: ());
$quickSearchMerchantFavoriteIconPositionRight: (small: 2rem, medium: 2rem);
$quickSearchMatchJustifyContent: space-between;
$quickSearchMatchDashDisplay: none;
$quickSearchMerchantRebateColor: $brandWhite;
$quickSearchMerchantRebateFontWeight: 600;
$quickSearchMerchantRebateTextTransform: uppercase;
$quickSearchMerchantRebateLetterSpacing: .46px;
$quickSearchMerchantRebateValueColor: $brandWhite;
$quickSearchMerchantRebateElevationOldValueFontWeight: 400;
$quickSearchMerchantRebateElevationOldValueColor: $brandWhite;
$quickSearchFooterProductLinkWrapBackground: $brandWhite;
$quickSearchResultsItemDisplay: block;
$quickSearchContentJustifyContent: (small: space-between, medium: flex-start);

/* Quick Links Tray */
$quickLinksTrayFilter: drop-shadow(0 1px 6px rgba(0, 0, 0, .75));
$quickLinksTrayBackgroundColor: url('~org/assets/images/quick_tray_back_swoosh.png');
$quickLinksTrayLinkSeparatorBorder: 1px solid #bac5d2;
$quickLinksTrayLinkFontSize: 1.2rem;
$quickLinksTrayLinkTextTransform: capitalize;
$quickLinksTrayLinkLetterSpacing: .6px;
$quickLinksTrayLinkFontWeight: bold;
$quickLinksTrayIconMargin: 0 auto .7rem;
$quickLinksTrayIconExtraRewardsUrl: url('~org/assets/images/icons/plane_icon.svg');
$quickLinksTrayIconExtraRewardsHeight: 1.2rem;
$quickLinksTrayIconExtraRewardsMarginBottom: 1.2rem;
$quickLinksTrayIconAccountUrl: url('~org/assets/images/icons/user_icon.svg');
$quickLinksTrayIconAccountHeight: 1.6rem;
$quickLinksTrayToggleFontSize: 1.2rem;
$quickLinksTrayToggleTextTransform: capitalize;
$quickLinksTrayToggleAfterFontFamily: 'fontello', serif;
$quickLinksTrayToggleAfterContent: '\f105';
$quickLinksTrayToggleAfterFontSize: 1.8rem;
$quickLinksTrayToggleAfterVerticalAlign: middle;
$quickLinksTrayIconAllStoresUrl: url('~org/assets/images/icons/star_white.svg');
$quickLinksTrayIconAllStoresHeight: 1.7rem;
$quickLinksTrayLinkLineHeight: 2rem;
$quickLinksTrayHeight: 8.5rem;
$quickLinksSearchIconBackground: url('~org/assets/images/quick-tray-search.svg');
$quickLinksSearchIconBoxShadow: 0 1px 6px 0 rgba(0, 0, 0, .75);

/* Search Modal */
$searchModalSearchFormBackground: $brandDarkerBlue;
$searchModalFlipTileBorder: none;
$searchModalQuickSearchResultsBorder: none;
$searchModalSearchInputBackground: $brandBlack;
$searchModalSearchInputColor: $brandWhite;
$searchModalSearchInputFontSize: 1.8rem;
$searchModalSearchInputMargin: 2.1rem auto 0;
$searchModalSearchInputWidth: 100%;
$searchModalQuickSearchMatchMatchTextColor: $brandWhite;
$searchModalQuickSearchMatchHighlightColor: $brandWhite;
$searchModalQuickSearchMatchFontSize: 1.3rem;
$searchModalQuickSearchDashColor: $brandButtonBackgroundColorActive;
$searchModalQuickSearchMatchRebateFontWeight: bold;
$searchModalQuickSearchMatchRebateTextAlign: end;
$searchModalQuickSearchMatchRebateWhiteSpace: nowrap;
$searchModalSearchFormPadding: 1rem 1.8rem;
$searchModalProductLinkWrapBorderTop: 1px solid rgba(54, 73, 90, .15);
$searchModalRecentOrSimilarStoresTileDisplay: flex;
$searchModalRecentOrSimilarStoresTileJustifyContent: space-between;
$searchModalRecentOrSimilarStoresHeaderFontWeight: bold;
$searchModalRecentOrSimilarStoresHeaderFontSize: 1.4rem;
$searchModalRecentOrSimilarStoresHeaderColor: $brandWhite;
$searchModalRecentOrSimilarStoresHeaderTextTransform: uppercase;
$searchModalRecentOrSimilarStoresSectHeadJustifyContent: left;
$searchModalRecentOrSimilarStoresTileRebateColor: $brandWhite;
$searchModalRecentOrSimilarStoresTileRebateFontSize: 1.3rem;
$searchModalRecentOrSimilarStoresTileRebateValueColor: $brandWhite;
$searchModalRecentOrSimilarStoresTileRebateTextAlign: right;
$searchModalRecentOrSimilarStoresTileBorderBottom: none;
$searchModalRecentOrSimilarStoresTileFavoriteIconWrapPosition: absolute;
$searchModalRecentOrSimilarStoresTileFavoriteIconWrapOrder: 0;
$searchModalQuickSearchMatchWidth: 100%;
$searchModalQuickSearchMatchHoverBackground: none;
$searchModalQuickSearchContentDisplay: flex;
$searchModalQuickSearchContentDashDisplay: none;
$searchModalQuickSearchMatchingTitlePadding: 1.5rem 0;
$searchModalQuickSearchMatchingTitleColor: $brandWhite;
$searchModalQuickSearchMatchingTitleFontWeight: bold;
$searchModalQuickSearchMatchingTitleTextTransform: uppercase;
$searchModalSearchSubmitBackground: url('~org/assets/images/icons/new_search_icon_white.svg') no-repeat center/80%;
$searchModalSearchSubmitPositionRight: 2.5rem;
$searchModalSearchSubmitIconDisplay: none;
$searchModalSearchSubmitPositionBottom: 1.8rem;
$searchModalQuickSearchDashDisplay: inline;
$searchModalProductLinkWrapBorderBottom: 1px solid rgba(186, 197, 210, .25);
$searchModalProductLinkWrapBorderTop: 1px solid rgba(186, 197, 210, .25);
$searchModalProductLinkColor: $brandWhite;
$searchModalProductLinkFontSize: 1.3rem;
$searchModalProductLinkAfterMargin: -.2rem 0 0 .5rem;
$searchModalProductLinkAfterContent: '\f105';
$searchModalProductLinkAfterFontFamily: 'fontello';
$searchModalProductLinkAfterFontSize: 2.2rem;
$searchModalProductLinkAfterFontWeight: 200;
$searchModalQuickSearchItemFavoriteIconPositionTop: 1.2rem;
$searchModalQuickSearchItemFavoriteIconFontBeforeFontSize: 1.6rem;
$searchModalProductLinkFontWeight: normal;
$searchModalProductLinkKeywordsFontWeight: bold;
$searchModalQuickSearchResultsPadding: 0 1.2rem;
$searchModalQuickSearchFavoriteIconPositionLeft: 0;
$searchModalRecentOrSimilarStoresTileLogoWrapMargin: -.6rem 1rem -.6rem 2.5rem;
$searchModalRecentOrSimilarStoresTileLogoWrapPadding: .6rem;
$searchModalRecentOrSimilarStoresTileLogoWrapBorderRadius: 0;
$searchModalRecentOrSimilarStoresTileFavoriteIconBeforeFontSize: 1.6rem;
$searchModalRecentOrSimilarStoresPadding: 0 1.2rem 2rem;
$searchModalRecentOrSimilarStoresTilePadding: 0;
$searchModalRecentOrSimilarStoresTileRebateWidth: 100%;
$searchModalQuickSearchFooterColor: $brandWhite;
$searchModalQuickSearchItemDisplay: flex;
$searchModalQuickSearchMatchPadding: .8rem 0 .8rem 2.3rem;

/* Credit Card Marketing Banner */
$creditCardBannerMaxWidth: unset;
$creditCardBannerMargin: (small: 0 -2rem, medium: 0, large: 0 -3rem, xlarge: ());
$creditCardBannerBackground: (small: #e6eaf2, medium: $brandLightGray3);
$creditCardBannerJustifyContent: center;
$creditCardBannerAlignItems: (medium: flex-start, large: center) ;
$creditCardBannerPadding: (small: 5.9rem 4rem 5.6rem 4rem, medium: 5.8rem 1rem 2.5rem, large: 5.8rem 1rem 2.5rem);
$creditCardBannerTextAlign: (small: center, medium: left);
$creditCardImageWrapWidth: (medium: 14.5rem, large: unset);
$creditCardImageWrapMargin: (medium: 0 2rem, large: unset);
$creditCardBannerImageMaxWidth: (small: 17.4rem, medium: 100%, large: 14.5rem);
$creditCardBannerImageMargin: (small: 0 auto 2rem, medium: 0 auto 2rem, large: -7rem 5.7rem -1.5rem);
$creditCardBannerWrapperPadding: (medium: 0, large: 0 3rem);
$creditCardBannerHeadingFontSize: 3rem;
$creditCardBannerHeadingFontWeight: (small: 300, medium: 400);
$creditCardBannerHeadingLineHeight: (small: 3.3rem, medium: 2.8rem);
$creditCardBannerHeadingMargin: .5rem 0 1.5rem;
$creditCardBannerTextTextAlign: (small: center, medium: left);
$creditCardBannerTextColor: $brandDarkerBlue;
$creditCardBannerTextMargin: (small: 1rem 0 2rem, medium: 0 0 .5rem);
$creditCardBannerTextFontSize: (small: 1.6rem, medium: 1.3rem);
$creditCardBannerTextFontWeight: (small: 600, medium: 700);
$creditCardBannerTextLineHeight: (small: 2.4rem, medium: 2.1rem);
$creditCardBannerTermsFontSize: (small: 1.5rem, medium: 1.4rem);
$creditCardBannerTermsLineHeight: 2.1rem;
$creditCardBannerTermsMargin: 0 0 1.8rem;
$creditCardBannerButtonMaxWidth: ();
$creditCardBannerButtonMinWidth: (medium: 18rem, large: 20rem, xlarge: 20rem);
$creditCardBannerButtonPadding: (small: 1.3rem .8rem, large: 1.1rem .8rem);
$creditCardBannerButtonMaxHeight: 4.5rem;
$creditCardBannerButtonFontSize: (small: 1.5rem, medium: 1.3rem);
$creditCardBannerTipColor: #02132d;
$creditCardBannerTipFontSize: 1rem;
$creditCardBannerTipLineHeight: 1.2rem;
$creditCardBannerTipLetterSpacing: 1.93px;
$creditCardBannerTipTextTransform: uppercase;
$creditCardBannerTipFontWeight: 700;
$creditCardBannerInfoWrapMargin: 0 2rem;

/* Trending And Seasonal Offers */
$trendingAndSeasonalOffersBackground: (small: $brandLightGray3, medium: none);

/* EmailOptIn MHP */
$emailOptInWrapperSectionPadding: (small: 5rem 3rem 7.3rem);
$emailOptInImageBackground: url('~org/assets/images/email_optin.png') no-repeat 50% 0/contain;
$emailOptInImageWidth: 18.8rem;
$emailOptInImageHeight: 18.8rem;
$emailOptInImageMargin: 0 auto 3rem;
$emailOptInWrapperSectionBackground: (small: $brandLightGray4);
$emailOptInWrapperHeight: 55rem;
$emailOptInHeadingDotDisplay: none;
$emailOptInTextFontWeight: 500;
$emailOptInTextColor: $brandDarkerBlue;
$emailOptInSwitcherWrapperWidth: 27.5rem;
$emailOptInWrapperOptedInBackground: #e6eaf2;
$emailOptInSwitcherPositionBottom: -2.9rem;
$emailOptInWrapperOptedInPadding: 5rem .7rem 7.3rem;
$emailOptInWrapperOptedInTextMargin: 0 0 4rem;

/* EmailSubscribe MHP */
$emailSubscribePanelPadding: 2.5rem 5rem 2.5rem;
$emailSubscribePanelBackground: #021026;
$emailSubscribeModalBackground: #021026;
$emailSubscribeModalPositionTop: 30%;
$emailSubscribeModalMinHeight: 0;
$emailSubscribeModalWidth: (small: initial, medium: 57rem);
$emailSubscribeModalPadding: 2.5rem 8rem 4rem;
$emailSubscribeModalOutline: none;
$emailSubscribePanelTextAlign: center;
$emailSubscribeOptInImageBackground: url('~org/assets/images/onboarding/emailOptIn.png') no-repeat 50% 0/contain;
$emailSubscribeOptInImageHeight: 8.8rem;
$emailSubscribeOptInImageMargin: (medium: 0 0 1.3rem);
$emailSubscribeCloseWidth: (small: 2rem);
$emailSubscribeCloseHeight: (small: 2rem);
$emailSubscribeCloseColor: (small: $brandWhite, medium: $brandWhite);
$emailSubscribeHeadingFont: (small: normal 3.3rem/4rem $brandFontFamily, medium: normal 3.3rem/4rem $brandFontFamily);
$emailSubscribeHeadingColor: (small: $brandWhite, medium: $brandWhite);
$emailSubscribeHeadingMargin: (small: 1rem 0, medium: 0 0 .5rem);
$emailSubscribeContentFont: (small: normal 1.6rem/1.4 $brandFontFamily, medium: normal 1.6rem/1.4 $brandFontFamily);
$emailSubscribeContentColor: (small: $brandWhite, medium: $brandWhite);
$emailSubscribeControlsMargin: (small: 0);
$emailSubscribeSubmitFontSize: (small: 1.5rem);
$emailSubscribeSubmitLineHeight: 1;
$emailSubscribeSubmitPadding: (small: 1.7rem 1.6rem, medium: 1.7rem 5.5rem);
$emailSubscribeSubmitMargin: (small: 1.5rem 0, large: 2.2rem 0 .8rem);
$emailSubscribeCancelLinkDisplay: (medium: initial);
$emailSubscribeCancelLinkFont: (small: normal 1.3rem/1.82rem $brandFontFamily, medium: normal 1.3rem/1.82rem $brandFontFamily);
$emailSubscribeCancelLinkLabelIconDisplay: none;
$emailSubscribeButtonLabelPaddingRight: 0;
$emailSubscribeButtonLabelColor: $brandWhite;

/* Email OptIn Success Modal */
$emailSuccessMessageColor: $brandWhite;
$emailSuccessMessageBackground: $brandBlack;
$emailSuccessMessageCloseButtonBeforeColor: $brandWhite;
$emailSuccessMessageCloseButtonBeforeContent: '\2715';
$emailSuccessMessageCloseButtonBeforeFontSize: 2rem;
$emailSuccessMessageCloseButtonBeforeFontWeight: 700;
$emailSuccessMessageH2Margin: 0 0 .5rem;
$emailSuccessMessageH2Color: $brandWhite;
$emailSuccessMessageH2FontWeight: 500;
$emailSuccessMessageOlayDescFontSize: 2rem;
$emailSuccessMessageOlayDescLineHeight: 1.4;
$emailSuccessModalBackground: $brandBlack;
$emailSuccessModalCloseButtonColor: $brandWhite;
$emailSuccessModalContentH2Color: $brandWhite;
$emailSuccessModalContentH2FontSize: 3rem;
$emailSuccessModalContentH2FontWeight: 500;
$emailSuccessModalContentH2Margin: 0 0 .5rem;
$emailSuccessModalContentTextColor: $brandWhite;
$emailSuccessModalContentTextFontSize: 2rem;
$emailSuccessModalContentTextLineHeight: 1.4;
$emailSuccessModalTextAlign: center;

/* Fullscreen */
$fullScreenBackground: (small: $brandWhite, medium: rgba(0, 0, 0, .6));

/* Onboarding */
$onboardingBrandLogoBackground: url('~org/assets/images/onboarding/delta_color_logo.svg') no-repeat center;
$onboardingBrandLogoHeight: 7.7rem;
$onboardingBrandLogoFloat: none;
$onboardingBrandLogoBackgroundSize: (small: 30rem, large: 36.4rem);
$onboardingBrandLogoWidth: 36.4rem;
$onboardingBrandLogoMargin: (small: 0 auto 6.5rem);
$onboardingAddToFavoriteIconWidth: 1.7rem;
$onboardingAddToFavoriteIconHeight: 1.5rem;
$onboardingPageCounterPosition: absolute;
$onboardingPageCounterPositionTop: 2rem;
$onboardingPageCounterPositionLeft: auto;
$onboardingPageCounterPositionRight: 2.5rem;
$onboardingPageCounterFontSize: 1.4rem;
$onboardingPageCounterFontWeight: 500;
$onboardingPageCounterTextTransform: uppercase;
$onboardingPageCounterColor: $brandTextColor;
$onboardingWelcomeStepHeaderMargin: (small: 0 auto 1.9rem, medium: 0 auto 1.9rem, large: 0 auto 1.5rem);
$onboardingWelcomeStepHeaderDotDisplay: inline;
$onboardingWelcomeDoneTop: 46rem;
$onboardingWelcomeMarginTop: (small: 5rem, large: 3rem);
$onboardingH2FontSize: (small: 3rem);
$onboardingH2LineHeight: (small: 3.5rem, large: 4.1rem);
$onboardingH2FontWeight: normal;
$onboardingH2Margin: (small: 0 0 1.9rem);
$onboardingH2Color: $brandDarkerBlue;
$onboardingH2AfterDisplay: block;
$onboardingH2AfterWidth: 7.5rem;
$onboardingH2AfterBorderBottom: 2px solid $brandRed3;
$onboardingH2AfterMargin: 0 auto;
$onboardingH2AfterPaddingTop: 1.5rem;
$onboardingPColor: $brandDarkerBlue;
$onboardingPFontSize: (small: 1.8rem);
$onboardingPFontWeight: 500;
$onboardingPLineHeight: (small: 2.4rem, large: 3rem);
$onboardingBottomContentPositionTop: (large: 43rem);
$onboardingBottomContentPadding: (small: 2rem);
$onboardingStepButtonHeight: 5rem;
$onboardingStepButtonFontWeight: 700;
$onboardingStepButtonFontSize: 1.5rem;
$onboardingStepButtonMargin: (small: 1.5rem 0 0, large: 0 auto 1.5rem);
$onboardingStepButtonBorder: none;
$onboardingStepButtonWidth: (large: 28rem);
$onboardingStepButtonOptinDisableColor: $brandGray4;
$onboardingStepButtonOptinDisableBackground: $brandGray5;
$onboardingOptInImageBackground: url('~org/assets/images/onboarding/emailOptIn.png') no-repeat 50% 0/contain;
$onboardingOptInImageHeight: 14.2rem;
$onboardingOptInImageMargin: (small: 0 auto 3.2rem);
$onboardingOptInHeaderMargin: (small: 6.5rem auto 2.8rem, large: 0 auto 1.5rem);
$onboardingOptInHeaderPadding: 0 1.5rem;
$onboardingOptInHeaderFontSize: 3rem;
$onboardingOptInHeaderLineHeight: 3.4rem;
$onboardingOptInTextFontSize: 1.8rem;
$onboardingOptInTextLineHeight: 3rem;
$onboardingOptInTextMargin: 0 3.4rem 2rem;
$onboardingOptInTextWidth: auto;
$onboardingOptinLabelColor: $brandDarkerBlue;
$onboardingOptinLabelFontSize: 1.4rem;
$onboardingOptinLabelFontWeight: 500;
$onboardingOptinLabelDotDisplay: inline;
$onboardingOptinLabelMargin: 0;
$onboardingFavoritesMargin: (small: 1.6rem 0 0, large: 3.4rem 0 0);
$onboardingFavoritesHeadingMargin: (small: 0 5rem .9rem, large: 0 0 1.5rem 0);
$onboardingFavoritesHeadingFontSize: (small: 3rem);
$onboardingFavoritesHeadingLineHeight: (small: 3.5rem, large: 4.1rem);
$onboardingFavoritesTextFontSize: 1.8rem;
$onboardingFavoritesTextMargin: 0 0 1rem;
$onboardingFavoritesTextLineHeight: (small: 2.4rem, large: 3rem);
$onboardingFavoritesDescriptionTextTransform: lowercase;
$onboardingFavoritesDescriptionFirstLetterTextTransform: uppercase;
$onboardingFavoritesPickFavoritesWidth: (small: auto, large: 66.4rem);
$onboardingFavoritesPickFavoritesCarouselWidth: (medium: 62rem);
$onboardingFavoritesPickFavoritesCarouselArrowPositionRight: (small: (), medium: calc(100% - 1rem));
$onboardingFavoritesPickFavoritesCarouselArrowPositionLeft: (small: (), medium: calc(100% - 1rem));
$onboardingFavoritesPickFavoritesCarouselMargin: (medium: 0 auto);
$onboardingFavoritesPickFavoritesBackground: (small: $brandLightGray3);
$onboardingFavoritesPickFavoritesMargin: (small: auto, large: auto);
$onboardingFavoritesPickFavoritesBorderWidth: (small: 1px 0 1px 0, large: 1px 1px 1px 1px);
$onboardingFavoritesPickFavoritesPadding: (small: 1.5rem 0 0, large: 0);
$onboardingFavoritesPickFavoritesTextAlign: left;
$onboardingFavoritesPickFavoritesHeight: (small: 24rem, large: 24rem);
$onboardingFavoritesMerchantLogoWidth: (small: 17rem, large: auto);
$onboardingFavoritesMerchantLogoPadding: (small: 1.1rem .5rem 2.1rem, large: 1.2rem 3.6rem 0 0);
$onboardingFavoritesMerchantLogoMargin: (small: 0 .8rem 1rem, large: auto);
$onboardingFavoritesPotentialFavoriteWidth: 13.5rem;
$onboardingFavoritesPotentialFavoriteHeight: 5.6rem;
$onboardingFavoritesPotentialFavoriteBackground: (large: $brandWhite);
$onboardingFavoritesPotentialFavoriteMargin: (medium: none, large: .9rem);
$onboardingFavoriteIconFontSize: 1.5rem;
$onboardingFavoriteIconPositionTop: (small: .5rem, large: 2rem);
$onboardingFavoriteIconPositionRight: (small: .7rem, large: -.1rem);
$onboardingFavoriteIconWidth: 2.4rem;
$onboardingFavoriteIconHeight: (small: 2rem);
$onboardingFavoritesPickFavoritesBorder: 1px solid #979797;
$onboardingFavoritesPickFavoritesBoxShadow: none;
$onboardingFavoritesCarouselArrowPrevBeforeContent: '\f104';
$onboardingFavoritesCarouselArrowNextBeforeContent: '\f105';
$onboardingFavoritesCarouselArrowNextBeforeBackground: inherit;
$onboardingFavoritesCarouselArrowPrevBeforeBackground: inherit;
$onboardingFavoritesCarouselArrowBeforeFontFamily: 'Fontello';
$onboardingFavoritesCarouselArrowBeforeFontSize: 2.6rem;
$onboardingFavoritesCarouselArrowDisplay: inline-block;
$onboardingFavoritesCarouselArrowHeight: 2rem;
$onboardingFavoritesCarouselArrowWidth: 2rem;
$onboardingFavoritesCarouselArrowNextPositionRight: .7rem;
$onboardingFavoritesCarouselArrowPrevPositionLeft: .5rem;
$onboardingFavoritesCarouselArrowNextTop: 45%;
$onboardingFavoritesCarouselArrowPrevTop: 45%;
$onboardingFavoritesCarouselArrowPrevZIndex: 1;
$onboardingFavoritesCarouselSlickListMargin: 0 2.5rem;
$onboardingStepLaterLinkFontWeight: 600;
$onboardingStepLaterLinkFontSize: 1.3rem;
$onboardingStepLaterLinkLetterSpacing: .46px;
$onboardingStepLaterLinkMargin: (small: auto, large: 0 auto);
$onboardingStepLaterLinkColor: $brandBlue4;
$onboardingStepLaterLinkTextDecoration: (small: none);
$onboardingFavoriteLaterLinkAfterDisplay: none;
$onboardingFavoriteLaterLinkDotDisplay: inline-block;
$onboardingFavoriteStepButtonMargin: (large: 0 auto 1.5rem);
$onboardingTurnOffAlertsColor: #3d3c58;
$onboardingTurnOffFontSize: (small: 1.3rem);
$onboardingTurnOffLineHeight: (small: 1.4rem);
$onboardingTurnOffMargin: (small: .3rem 0 0, large: 0);
$onboardingGetTheButtonBackground: url('~org/assets/images/onboarding/delta_button.png') center no-repeat;
$onboardingGetTheButtonHeight: (small: 14rem, large: 20rem);
$onboardingGetTheButtonWidth: (small: 20rem, large: 20rem);
$onboardingGetTheButtonMargin: (small: 0 1rem 1rem 0, large: 0 1rem 0 1.5rem);
$onboardingButtonStepDescriptionFlexDirection: (small: column, large: unset);
$onboardingButtonStepHeadingMargin: (small: 3.5rem 1.5rem 2rem, large: 3.3rem 1.5rem 1rem);
$onboardingButtonStepHeadingWidth: (small: auto);
$onboardingButtonStepHeadingFontSize: (small: 3rem);
$onboardingButtonStepHeadingLineHeight: (small: 4rem, large: 4.4rem);
$onboardingButtonStepPMargin: (small: 0 1.5rem 2rem, large: 0 1.5rem 4.8rem);
$onboardingButtonStepListWidth: (small: 100%, large: 64%);
$onboardingButtonStepListPadding: (small: 1rem 1.5rem 0);
$onboardingButtonStepListMargin: 0 0 1rem;
$onboardingButtonStepBulletTextFontSize: (small: 1.3rem, large: 1.6rem);
$onboardingButtonStepBulletTextFontWeight: 500;
$onboardingButtonStepBulletTextWidth: 100%;
$onboardingButtonStepBulletTextPadding: .5rem 0 1rem 0;
$onboardingButtonStepBulletTextBorderBottom: 1px solid #818a98;
$onboardingButtonStepButtonMargin: (small: 1.5rem 0 0, large: 0 auto 1.5rem);
$onboardingButtonStepLaterLinkAfterDisplay: none;
$onboardingCongratsHeadingDisplay: inline-block;
$onboardingCongratsHeadingPaddingTop: (small: 5rem);
$onboardingCongratsHeadingColor: $brandDarkBlue;
$onboardingCongratsHeadingFontSize: (small: 3rem, large: 3rem);
$onboardingCongratsHeadingFontWeight: (small: 400);
$onboardingCongratsImgMargin: 0 .8rem -.4rem 0;
$onboardingCongratsPMargin: (small: -.4rem 3.4rem 0);
$onboardingCongratsPLetterSpacing: .41px;
$headerOnboardingCongratsIconDisplay: inline;
$headerOnboardingCongratsIconMargin: 0 1rem -.7rem 0;
$headerOnboardingCongratsIconHeight: 4rem;
$headerOnboardingCongratsIconWidth: 4rem;
$headerOnboardingCongratsTitleFontSize: 3rem;
$headerOnboardingCongratsTitleLineHeight: 3.4rem;
$headerOnboardingCongratsTitleMargin: 2.2rem 0 -.5rem;
$headerOnboardingCongratsTextFontSize: 1.8rem;
$headerOnboardingCongratsTextFontWeight: 500;
$headerOnboardingCongratsH2Color: $brandDarkerBlue;
$headerOnboardingCongratsTextColor: $brandDarkerBlue;

/* Switcher */
$switcherCheckedBackground: #991933;

/* Pick Favorites */
$pickFavoritesBackground: $brandLightGray3;
$pickFavoritesMerchantLogoTileBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .4);
$pickFavoritesButtonBackground: $brandWhite;
$pickFavoritesButtonColor: #0b1f66;
$pickFavoritesButtonBorder: 2px solid #bac5d2;
$pickFavoritesButtonPadding: 1rem .8rem;
$pickFavoritesMerchantLogoTileFavoriteIconWidth: 1.5rem;
$pickFavoritesMerchantLogoTileFavoriteIconHeight: 1.5rem;
$pickFavoritesButtonHoverColor: #0b1f66;
$pickFavoritesButtonHoverBackground: $brandWhite;

/* Pill */
$pillBackground: $brandRed2;
$pillLabelColor: $brandWhite;
$pillHeight: 1.8rem;
$pillBorderRadius: 10px;
$pillLabelFontSize: 1rem;
$pillLabelLineHeight: 1.8rem;
$pillLabelLetterSpacing: (small: 1.3px, medium: 1.14px);
$pillLabelFontWeight: 700;
$pillLabelTextTransform: uppercase;
$pillLabelAfterBackground: url('../assets/images/icons/plane_icon.svg') no-repeat;
$pillLabelAfterWidth: 1.8rem;
$pillLabelAfterHeight: 1rem;
$pillLabelAfterPositionLeft: .5rem;
$pillLabelAfterPositionTop: .2rem;

/* Merchant Tile */
$merchantTileBoxShadow: (small: none, medium: 0 2px 4px 0 rgba(255, 255, 255, .5));
$merchantTileBorder: (small: 1px solid #e6eaf2, medium: 1px solid #e8ecf2);
$merchantTileBorderRadius: 0;
$merchantTileRebateBackground: (small: #e6eaf2, medium: $brandLightGray4);
$merchantTileRebateHeight: (small: 6.5rem, medium: 7rem);
$merchantTilePillWidth: (small: 10rem, medium: 10rem);
$merchantTilePillBottom: 4.8rem;
$merchantTileFavoriteIconWidth: (small: 1.7rem, medium: 2.1rem);
$merchantTileFavoriteIconHeight: (small: 1.7rem, medium: 1.8rem);
$merchantTileFavoriteIconPositionTop: (small: 3px, medium: 1px);
$merchantTileFavoriteIconPositionRight: (small: 3px, medium: 3px);
$merchantTileItemPositionTop: (small: .2rem);
$merchantTileItemPositionBottom: (small: .2rem);
$merchantTileItemPositionLeft: (small: .2rem);
$merchantTileItemPositionRight: (small: .2rem);

/* Merchant With Offer Count */
$merchantWithOfferCountBorder: (small: 1px solid #e6eaf2, medium: 1px solid $brandLightGray2);
$merchantWithOfferCountRebatePositionTop: unset;
$merchantWithOfferCountRebatePositionBottom: 0;
$merchantWithOfferCountRebateHeight: 7.7rem;
$merchantWithOfferCountRebateBackground: (small: #e6eaf2, medium: $brandLightGray4);
$merchantWithOfferCountH3Color: #323232;
$merchantWithOfferCountH3FontSize: 1.3rem;
$merchantWithOfferCountH3LineHeight: 2.3rem;
$merchantWithOfferCountH3FontWeight: 300;
$merchantWithOfferCountPillWidth: 10rem;
$merchantWithOfferCountPillPositionBottom: 7rem;
$merchantWithOfferCountFavoriteIconWidth: 1.7rem;
$merchantWithOfferCountFavoriteIconHeight: 1.7rem;
$merchantWithOfferCountFavoriteIconPositionRight: 5px;
$merchantWithOfferCountTilePositionTop: 2px;
$merchantWithOfferCountTilePositionBottom: 2px;
$merchantWithOfferCountTilePositionLeft: 2px;
$merchantWithOfferCountTilePositionRight: 2px;
$merchantWithOfferCountBoxShadow: (small: (), medium: 0 2px 4px 0 rgba(255, 255, 255, .5));

/* Marketing Merchants Home */
$marketingMerchantsHomeTileWidth: (small: 22.7rem, medium: 12rem);
$marketingMerchantsHomeTileHeight: (small: 23.7rem, medium: 13.3rem);
$marketingMerchantsHomeViewAllLinkInsideSliderBackground: $brandSecondaryColor;
$marketingMerchantsHomeViewAllLinkInsideSliderBorder: 1px solid #e6eaf2;
$marketingMerchantsHomeViewAllLinkInsideSliderColor: $brandWhite;
$marketingMerchantsHomeViewAllLinkInsideSliderFontSize: 1.5rem;
$marketingMerchantsHomeViewAllLinkInsideSliderFontWeight: 500;
$marketingMerchantsHomeViewAllLinkInsideSliderTextTransform: capitalize;
$marketingMerchantsHomeMerchantTileRebateElevationOldFontSize: 1.2rem;
$marketingMerchantsHomeMerchantTileRebateElevationNewFontSize: 1.2rem;
$marketingMerchantsHomeMerchantTileRebateElevationNewLineHeight: 1.4rem;
$marketingMerchantsHomeMerchantTileRebateHeight: 5.5rem;
$marketingMerchantsHomePillPositionTop: (small: 62%, medium: 49%);
$marketingMerchantsHomeViewAllLinkHoverTextDecoration: none;

/* Lightbox */
$lightboxCloseIconContent: '\2715';
$lightboxCloseFontSize: (small: 1.7rem,  medium: ());
$lightboxCloseFontWeight: (small: bold, medium: ());

/* Modal */
$modalCloseButtonFontWeight: (small: bold);
$modalCloseButtonColor: #3a5a76;
$modalCloseButtonFontSize: (small: 1.7rem, medium: ());

/* Offer Overlay */
$merchantOverlayModalCloseButtonColor: (small: #fff, medium: #3a5a76);
$merchantOverlayHeadLogoOffsetTop: (small: 3rem, medium: -4.8rem);
$merchantOverlayHeadLogoBorderRadius: (small: 0, medium: 4px);
$merchantOverlayFavoriteIconPositionRight: (small: .4rem, medium: .5rem);
$merchantOverlayFavoriteIconPositionTop: (small: .7rem, medium: 1rem);
$merchantOverlayFavoriteIconWidth: (small: 1.25rem, medium: 1.7rem);
$merchantOverlayFavoriteIconHeight: (small: 1.2rem, medium: 1.7rem);
$merchantOverlayHeadLogoPadding: (small: 1.5rem 2rem, medium: 2.4rem 2.4rem 0);
$merchantOverlayContentPadding: (small: 0, medium: 0 1rem 1rem 1rem);
$merchantOverlayDescriptionMargin: (small: 0,  medium: 2rem 0 0);
$merchantOverlayOfferTitleFont: (small: 300 2.4rem/2.6rem $brandFontFamily, medium: 400 3rem/4rem $brandFontFamily);
$merchantOverlayOfferTitleTextTransform: none;
$merchantOverlayOfferTitleMargin: (small: 2rem 1.5rem 1rem, medium: 4rem 0 1rem);
$merchantOverlayHeadRebatePadding: (small: 2rem 0 .95rem, medium: 1rem 0 0);
$merchantOverlayHeadRebateBorderBottom: (small: 1px solid rgba(0, 0, 0, .1), medium: 1px solid rgba(0, 0, 0, .1));
$merchantOverlayHeadOfferExpiresFontSize: (small: 1.3rem);
$merchantOverlayHeadOfferExpiresMargin: (small: 0 0 1rem);
$merchantOverlayHeadOfferExpiresColor: (small: $brandTextColor);
$merchantOverlayHeadOfferSeparatorDisplay: (small: none, medium: ());
$merchantOverlayHeadRebateMargin: (small: 0);
$merchantOverlayHeadRebateFontSize: (small: 1.4rem);
$merchantOverlayRebateOldValueDisplay: (small: block, medium: inline);
$merchantOverlayRebateNewValueFontSize: (small: 1.3rem, medium: 1.8rem);
$merchantOverlayRebateOldValueColor: #636363;
$merchantOverlayRebatePrefixTextTransform: uppercase;
$merchantOverlayRebatePrefixFontSize: (small: 1.3rem, medium: 1.8rem);
$merchantOverlayRebatePrefixFontWeight: (small: 600, medium: 600);
$merchantOverlayRebateOldValueFontSize: (small: 1.3rem, medium: 1.8rem);
$merchantOverlayRebateOldValueTextTransform: none;
$merchantOverlayRebateOldValueAfterContent: ',';
$merchantOverlayRebateValueWithCurrencyFontSize: (small: 1.3rem, medium: 1.8rem);
$merchantOverlayRebateNewValueColor: $brandBlue4;
$merchantOverlayRebateNewValueTextTransform: uppercase;
$merchantOverlayRebateNewValueFontWeight: 600;
$merchantOverlayMerchantRebateFontSize: 1.4rem;
$merchantOverlayRebateValueWithCurrencyFontWeight: 600;
$merchantOverlayRebateValueWithCurrencyTextTransform: uppercase;
$merchantOverlayHeadRebateFontWeight: (small: 600);
$merchantOverlayRebatePrefixColor: (small: $brandBlue4);
$merchantOverlayShopNowBackgroundColor: $brandRed2;
$merchantOverlayShopNowMargin: (small: 2rem 0, medium: 2rem 0 5rem);
$merchantOverlayShopNowFont: (small: 700 1.5rem/2.4rem $brandFontFamily, medium: 700 1.4rem/1.9rem $brandFontFamily);
$merchantOverlayShopNowBorder: none;
$merchantOverlayShopNowPadding: (small: 1.3rem 0, medium: 1rem);
$merchantOverlayShopNowBorderRadius: 4px;
$merchantOverlayShopNowMinWidth: (small: 25.8rem,  medium: 20rem);
$merchantOverlayShopNowTextTransform: uppercase;
$merchantOverlayFooterLinkFontWeight: (small: 600, medium: ());
$merchantOverlayFooterLinkFontSize: (small: 1.3rem, medium: 1.6rem);
$merchantOverlayFooterLinkColor: $brandBlue4;
$merchantOverlayFooterLinkTextTransform: uppercase;
$merchantOverlayFooterMerchantTermsMargin: (small: .5rem 0 0, medium: ());
$merchantOverlayFooterTermsFontSize: (small: 1.3rem, medium: 1.3rem);
$merchantOverlayFooterMerchantTermsColor: (small: $brandDarkerBlue, medium: $brandGray3);
$merchantOverlayFooterTermsTextDecoration: none;
$merchantOverlayFooterTermsFontWeight: (small: 400, medium: ());
$merchantOverlayFooterTermsTextTransform: none;
$merchantOverlayDecorationDisplay: (small: block, medium: none);
$merchantOverlayDecorationHeight: 14.6rem;
$merchantOverlayDecorationBackground: #036;
$merchantOverlayDecorationPosition: relative;
$merchantOverlayDecorationAfterContent: '';
$merchantOverlayDecorationAfterDisplay: block;
$merchantOverlayDecorationAfterWidth: 100%;
$merchantOverlayDecorationAfterHeight: 5.6rem;
$merchantOverlayDecorationAfterBackground: #002a50;
$merchantOverlayDecorationAfterPosition: absolute;
$merchantOverlayDecorationAfterPositionBottom: 0;
$merchantOverlayDisclaimerOrder: (small: 3, medium: 3);
$merchantOverlayOfferTitleOrder: (small: 2, medium: 2);
$merchantOverlayHeadOfferExpiresOrder: (small: 4, medium: 4);
$merchantOverlayPremierMerchantRebateOrder: (small: 1, medium: 1);
$merchantOverlayExtraPillWidth: 12rem;
$merchantOverlayExtraPillPositionTop: 14rem;
$merchantOverlayExtraPillLabelLetterSpacing: 1.08px;
$merchantOverlayDisclaimerFontSize: (small: 1.2rem, medium: ());
$merchantOverlayDisclaimerMargin: 0 auto;
$merchantOverlayDisclaimerWidth: ();
$merchantOverlayDescriptionDisplay: (small: flex);
$merchantOverlayDescriptionFlexDirection: (small: column);
$merchantOverlayArrowLinkDisplay: (small: none, medium: inline-block);
$merchantOverlayCouponControlsJustifyContent: center;
$merchantOverlayCouponCodeInputMaxWidht: (small: 21rem);
$merchantOverlayCouponCodeInputHeight: (small: 5rem);
$merchantOverlayCouponCodeCopyWidth: (small: 8rem);
$merchantOverlayCouponCodeCopyHoverBackground: rgba(98, 122, 136, .25);
$merchantOverlayCouponCodeCopyHoverColor: #0b1f66;
$merchantOverlayCouponCodeIsCopiedColor: #0b1f66;
$merchantOverlayCouponCodeIsCopiedBackground: rgba(98, 122, 136, .25);
$merchantOverlayCouponCodeInputHeight: (small: 5rem);
$merchantOverlayCouponCodeMarginLeft: -1px;
$merchantOverlayCouponIntroFontSize: (small: 1.3rem, medium: 1.3rem);
$merchantOverlayCouponIntroColor: (small: $brandDarkerBlue, medium: $brandDarkerBlue);
$merchantOverlayCouponIntroMargin: (small: 1rem 0, medium: 1rem 0);
$merchantOverlayCouponMargin: 1rem 0 2rem;
$merchantOverlayCouponCodeInputBackgroundColor: (small: #e6eaf2, medium: #e6eaf2);
$merchantOverlayCouponCodeInputPadding: (small: 1.5rem 1.8rem, medium: .2rem 0 .2rem 1rem);
$merchantOverlayCouponCodeInputBorder: (small: none, medium: none);
$merchantOverlayCouponCodeInputBorderRadius: (small: 3px 0 0 3px, medium: 3px 0 0 3px);
$merchantOverlayCouponCodeInputTextTransform: uppercase;
$merchantOverlayCouponCodeInputTextAlign: (small: center, medium: center);
$merchantOverlayCouponCodeInputMaxWidht: (small: 100%, medium: 100%);
$merchantOverlayCouponCodeInputHeight: (small: 5rem, medium: 3.5rem);
$merchantOverlayCouponCodeInputFont: (small: 600 1.3rem/1.5rem $brandFontFamily, medium: 600 1.4rem/2.1rem $brandFontFamily);
$merchantOverlayCouponCodeInputLetueterSpacing: .9px;
$merchantOverlayCouponCodeInputColor: (small: $brandDarkerBlue, medium: $brandDarkerBlue);
$merchantOverlayCouponCodeCopyBackgroundColor: $brandWhite;
$merchantOverlayCouponCodeCopyBorderColor: #e6eaf2;
$merchantOverlayCouponCodeCopyBorder: (small: 1px solid $merchantOverlayCouponCodeCopyBorderColor, medium: 1px solid #e7ecef);
$merchantOverlayCouponCodeCopyBorderRadius: (small: 0 3px 3px 0, medium: 0 3px 3px 0);
$merchantOverlayCouponCodeCopyFont: (small: bold 1.3rem/2.1rem $brandFontFamily, medium: 400 1.4rem/2.1rem $brandFontFamily);
$merchantOverlayCouponCodeCopyHeight: (small: 5rem, medium: 3.5rem);
$merchantOverlayCouponCodeCopyPadding: 0;
$merchantOverlayCouponCodeCopyColor: (small: #0b1f66, medium: $brandDarkerBlue);
$merchantOverlayCouponCodeCopyWidth: (small: 9rem, medium: 9rem);
$merchantOverlayCouponCodeCopyTextTransform: (small: uppercase, medium: none);

/* Popular Offers */
$popularOffersBackground: (small: $brandDarkerBlue, medium: none);
$popularOffersSectionTitleMargin: (small: (), medium: (), large: (), xlarge: 0 0 5.5rem 0);
$popularOffersSectionTitleH2Color: (small: $brandWhite, medium: $brandDarkerBlue);
$popularOffersViewAllLinkDisplay: none;
$popularOfferImageBorder: (small: 1px solid #e6eaf2, medium: none);
$popularOfferImageBorderRadius: 0;
$popularOfferImageBorderWidth: 1px 1px 0 1px;
$popularOfferRebateBackground: (small: #e6eaf2, medium: #f5f8fa);
$popularOfferRebateColor: $brandBlue4;
$popularOfferRebateBorder: (small: 2px solid $brandWhite, medium: none);
$popularOfferRebateBorderRadius: 0;
$popularOfferRebateFontSize: (small: 1.3rem, medium: 1.3rem);
$popularOfferRebateFontWeight: (small: 600, medium: 600);
$popularOfferRebateLetterSpacing: .46px;
$popularOfferRebateValueAndPrefixColor: $brandBlue4;
$popularOfferRebateValueAndPrefixFontWeight: (small: 600, medium: 600);
$popularOfferRebateOldValueColor: $brandLightBlue2;
$popularOfferRebateOldValueBorderTop: 2px solid $brandLightBlue2;
$popularOfferMerchantRebateTextTransform: uppercase;
$popularOfferBackground: $brandWhite;
$popularOfferBorder: (small: (), medium: 1px solid #e8ecf2);
$popularOfferRebatePrefixTextTransform: uppercase;

/* Bonus Merchant Offer */
$bonusMerchantOfferBorder: 1px solid #e8ecf2;
$bonusMerchantOfferBonusTagWrapColor: $brandWhite;
$bonusMerchantOfferBonusTagWrapFontSize: 1.3rem;
$bonusMerchantOfferBonusTagWrapBackground: $brandRed3;
$bonusMerchantOfferBonusTagWrapTextTransform: uppercase;
$bonusMerchantOfferBottomWrapBorderRadius: 0;
$bonusMerchantOfferBottomWrapBackground: $brandDarkerBlue;
$bonusMerchantOfferBottomWrapPadding: 1.3rem;
$bonusMerchantOfferBottomWrapHeight: 26rem;
$bonusMerchantOfferHeadingLineHeight: 2.9rem;
$bonusMerchantOfferHeadingFontWeight: 300;
$bonusMerchantOfferSeeDetailsFontWeight: 700;
$bonusMerchantOfferSeeDetailsTextTransform: capitalize;
$bonusMerchantOfferSeeDetailsAfterContent: '\f105';
$bonusMerchantOfferSeeDetailsAfterFontFamily: 'fontello', serif;
$bonusMerchantOfferSeeDetailsAfterFontSize: 2.2rem;
$bonusMerchantOfferSeeDetailsAfterPosition: relative;
$bonusMerchantOfferSeeDetailsAfterPositionBottom: -3px;
$bonusMerchantOfferSeeDetailsAfterPositionRight: -8px;
$bonusMerchantOfferSeeDetailsAfterFontWeight: 400;
$bonusMerchantOfferSeeDetailsAfterColor: $brandRed3;
$bonusMerchantOfferRebateTextTransform: uppercase;

/* Merchant Logo */
$merchantLogosSectionMargin: (small: 0);
$merchantLogosSectionPadding: (small: 4rem 0, medium: 0);
$merchantLogosLogoWrapperMaxWidth: (small: 9rem, large: 11rem);
$merchantLogosListLayoutColumns: (small: 5, medium: 3, large: 3, xlarge: 4);
$merchantLogosListLayoutColumnGap: (small: 1rem, medium: 2.5rem, large: 2rem, xlarge: 3rem);
$merchantLogosListLayoutRowGap: (small: 1rem, medium: 5rem, large: 5rem);

/* How It Works */
$howItWorksSectionTitleAlignItems: (small: center);
$howItWorksSectionTitleMargin: (small: 0 0 1rem 0);
$howItWorkSectionTitleH2FontSize: (small: 3.5rem);
$howItWorksInvertedSectionBackground: (small: $brandDarkerBlue);
$howItWorksItemWidth: 15.5rem;
$howItWorksItemTitleFontSize: (small: 2.4rem);
$howItWorksItemTitleLineHeight: (small: 3.2rem);
$howItWorksItemTitleFontWeight: (small: 600);
$howItWorksItemDescriptionMaxWidth: 33rem;
$howItWorksItemDescriptionPadding: 0;
$howItWorksItemDescriptionLineHeight: (small: 2.5rem);
$howItWorksInvertedDotsButtonBeforeFontSize: 1.2rem;
$howItWorksInvertedDotsButtonBeforeColor: transparent;
$howItWorksInvertedDotsButtonBeforeBorder: 1px solid #bac5d2;
$howItWorksInvertedDotsButtonBeforeBorderRadius: 50%;
$howItWorksInvertedDotsButtonActiveBeforeBorder: none;
$howItWorksInvertedDotsButtonBeforeWidth: .8rem;
$howItWorksInvertedDotsButtonBeforeHeight: .8rem;
$howItWorksInvertedDotsButtonBeforeLineHeight: 1.3rem;
$howItWorksInvertedDotsButtonActiveBeforeColor: #bac5d2;
$howItWorksInvertedDotsButtonBeforePositionLeft: unset;
$howItWorksInvertedDotsMargin: 0 .3rem;
$howItWorksItemMarginBottom: (small: 3rem);
$howItWorksVideoLinkColor: $brandDarkerBlue;
$howItWorksVideoLinkFontSize: 1.5rem;
$howItWorksVideoLinkFontWeight: 600;
$howItWorksVideoLinkLineHeight: 2.1rem;
$howItWorksVideoLinkLetterSpacing: .34px;
$howItWorksVideoLinkHoverTextDecoration: none;
$howItWorksVhpSectionBackground: (small: $brandLightGray3);

/* VHP How It Works  */
$howItWorksVhpSectionPadding: (small: 3rem 0 5rem 0, medium: 4rem);
$howItWorksVhpSectionTitleH2FontSize: (small: 3.2rem);
$howItWorksVhpSectionTitleH2LetterSpacing: (small: -.46px);
$howItWorksVhpSectionTitleH2LineHeight: (small: 4.6rem);
$howItWorksVhpWatchVideoLinkWrapperMaxWidth: 1rem 0 0;
$howItWorksVhpItemMargin: (small: 0);
$howItWorksVhpImageWidth: 22.6rem;
$howItWorksVhpItemTitleFontSize: 2.5rem;
$howItWorksVhpItemTitleLineHeight: 2.7rem;
$howItWorksVhpItemTitleMargin: 2.1rem 0 1.1rem;
$howItWorksVhpItemTitleColor: $brandDarkerBlue;
$howItWorksVhpItemTitleFontWeight: 300;
$howItWorksVhpItemTitleLetterSpacing: .57px;
$howItWorksVhpItemDescriptionFontSize: 1.4rem;
$howItWorksVhpItemDescriptionLineHeight: 2.7rem;
$howItWorksVhpItemDescriptionMaxWidth: 31.6rem;
$howItWorksVhpItemDescriptionColor: $brandPrimaryColor;
$howItWorksVhpItemDescriptionLetterSpacing: .36px;
$howItWorksVhpItemDescriptionFontWeight: 500;
$howItWorksVhpItemDescriptionPadding: 0 .2rem;

/* Button Benefits Home Page */
$homeButtonBenefitsHeaderFontSize: 3.2rem;
$homeButtonBenefitsHeaderLineHeight: 4rem;
$homeButtonBenefitsHeaderMargin: (small: 0 0 1.5rem 0, medium: 0 0 1.5rem 0);
$homeButtonBenefitsHeaderMinWidth: (small: (), medium: ());
$homeButtonBenefitsListFontSize: 1.4rem;
$homeButtonBenefitsListLineHeight: 3.2rem;
$homeButtonBenefitsListColor: $brandDarkerBlue;
$homeButtonBenefitsUListMargin: 0;
$homeButtonBenefitsRatingSpanFontWeight: 400;
$homeButtonBenefitsButtonFontSize: 1.3rem;
$homeButtonBenefitsButtonWidth: 25rem;
$homeButtonBenefitsButtonPadding: 1.6rem;
$homeButtonBenefitsButtonBackground: $brandRed3;
$homeButtonBenefitsButtonLineHeight: 1;
$homeButtonBenefitsButtonFontWeight: 700;
$homeButtonBenefitsButtonLetterSpacing: .8px;
$homeButtonBenefitsChromeMargin: (small: 1rem 0 1.5rem, medium: 0);

/* Trending Stores */
$trendingStoresNewMemberBackground: $brandDarkerBlue;
$trendingStoresNewMemberPadding: 3rem 0 6.1rem;
$trendingStoresDividerBackground: rgba(0, 0, 0, .1);
$trendingStoresMerchantLinkTilePillTop: (medium: 55%);
$trendingStoresMerchantLinkTileRebateHeight: (medium: 7rem, large: 7rem);
$trendingStoresMerchantLinkTileRebatePositionTop: (small: (), medium: 10.8rem);
$trendingStoresMerchantLinkTileRebateNewValueLineHeight: 1.7rem;
$trendingStoresVhpPadding: (small: 0 0 1.5rem, medium: 0 0 1.5rem, large: 0 0 2rem);
$trendingStoresVhpMargin: 0;
$trendingStoresVhpBorderBottom: none;
$trendingStoresVhpTitleMargin: 0 0 1rem;
$trendingStoresVhpH2FontSize: (small: 2.6rem, medium: 3rem);
$trendingStoresVhpH2FontWeight: 400;
$trendingStoresVhpH2Padding: 0;
$trendingStoresVhpMerchantLinkTileWidth: (small: 15.4rem, medium: (), large: 100%);
$trendingStoresVhpMerchantLinkTileHeight: (small: 18.4rem, medium: (), large: 100%);
$trendingStoresVhpMerchantLinkTilePillTop: (small: 53%, medium: 55%, large: 55%);
$trendingStoresVhpMerchantLinkTileRebateBackground: $brandLightGray4;
$trendingStoresVhpMerchantLinkTileRebatePositionTop: (small: 10.2rem, medium: 10.8rem, large: ());
$trendingStoresVhpMerchantLinkTileOffersCountDisplay: (small: none, medium: (), large: ());

/* Favorite Stores */
$favoriteStoresMerchantTileHeight: (small: 18.8rem, medium: 18.3rem);
$favoriteStoresMerchantTilePillPositionBottom: (small: 5.7rem, medium: 6.2rem);
$favoriteStoresManageFavoritesTileWrapPadding: (small: .2rem, medium: 0);
$favoriteStoresManageFavoritesTileWrapHeight: (small: 18.8rem, medium: 18.3rem);
$favoriteStoresManageFavoritesTileWrapBorder: 1px solid #e6eaf2;
$favoriteStoresManageFavoritesTileHeight: (small: 100%, medium: 18.3rem);
$favoriteStoresManageFavoritesTileFontSize: (small: 1.8rem, medium: 2.7rem);
$favoriteStoresManageFavoritesTileBackground: (small: #036, medium: #002a50);
$favoriteStoresManageFavoritesTileTextAlign: (small: center, medium: left);
$favoriteStoresManageFavoritesTileLineHeight: (small: 2.1rem, medium: 3.8rem);
$favoriteStoresManageFavoritesTileTextTransform: capitalize;
$favoriteStoresManageFavoritesTileLetterSpacing: (small: .4px, medium: 0);
$favoriteStoresManageFavoritesTileBorderRadius: (small: 0, medium: 0);
$favoriteStoresManageFavoritesTileFontWeight: (small: 500, medium: 300);
$favoriteStoresManageFavoritesTileArrowFontFamily: (small: (), medium: 'fontello');
$favoriteStoresManageFavoritesTileArrowContent: (small: (), medium: '\f105');
$favoriteStoresManageFavoritesTileArrowPadding: (small: (), medium: 0 0 0 .8rem);

/* Top Stores */
// Expandable
$topStoresExpandableBackground: #154067;
$topStoresExpandableTextColor: $brandWhite;
$topStoresExpandableMerchantTileMerchantRebateV2Background: #154067;
$topStoresExpandableMerchantTileLinkMargin: 0 0 3rem 0;
$topStoresExpandableMerchantTileMerchantRebateV2PositionTop: 15.5rem;
$topStoresExpandableMerchantTileMerchantRebateV2Height: 6rem;
$topStoresExpandableMerchantFavoriteIconHeight: 1.7rem;
$topStoresExpandableMerchantFavoriteIconWidth: 1.7rem;
$topStoresExpandableButtonContainerMargin: 2rem 0 0;
$topStoresExpandableMerchantTileBorder: 2px solid #e6eaf2;
$topStoresExpandableMerchantTileBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .2);
$topStoresExpandableButtonBackground: $brandWhite;
$topStoresExpandableButtonColor: #0b1f66;
$topStoresExpandableButtonBorder: 2px solid #bac5d2;
$topStoresExpandableButtonPadding: 1.3rem .8rem;
// Desktop
$topStoresMerchantTileWidth: 12rem;
$topStoresMerchantTileHeight: 13.3rem;
$topStoresMerchantTileBoxShadow: none;
$topStoresMerchantTileRebateHeight: 5.5rem;
$topStoresMerchantTileRebateElevationOldFontSize: 1.2rem;
$topStoresMerchantTileRebateElevationOldLineHeight: 1.3rem;
$topStoresMerchantTileRebateElevationNewFontSize: 1.2rem;
$topStoresMerchantTileRebateElevationNewLineHeight: 1.4rem;
$topStoresMerchantTileRebatePadding: 1rem 0 0;
$topStoresMerchantTileRebateElevationOldPadding: .3rem;
$topStoresMerchantTileFavoriteIconHeight: 1.5rem;
$topStoresMerchantTileFavoriteIconWidth: 1.5rem;

/* Personalized Top Offers */
$personalizedTopOffersButtonBorder: 2px solid #bac5d2;
$personalizedTopOffersButtonColor: #0b1f66;
$personalizedTopOffersButtonBackground: $brandWhite;
$personalizedTopOffersButtonPadding: 1.3rem .8rem;

/* Featured Deals */
// Rounded View
$featuredDealMobileRoundedMerchantLogoWrapMinWidth: 8.1rem;
$featuredDealMobileRoundedMerchantLogoWrapHeight: 8.1rem;
$featuredDealMobileRoundedFavoriteIconWidth: 1.7rem;
$featuredDealMobileRoundedFavoriteIconHeight: 1.7rem;
$featuredDealMobileRoundedMerchantNameFontWeight: 300;
$featuredDealMobileRoundedMerchantNameFontSize: 2.4rem;
$featuredDealMobileRoundedMerchantNameLineHeight: 2.6rem;
$featuredDealMobileRoundedMerchantNameMargin: .4rem 0 1rem;
$featuredDealMobileRoundedRebatePrefixFontSize: 1.3rem;
$featuredDealMobileRoundedRebateFontSize: 1.3rem;
$featuredDealMobileRoundedContentFontSize: 1.3rem;
$featuredDealMobileRoundedContentLineHeight: 1.8rem;
$featuredDealMobileRoundedCouponCodeFontSize: 1.3rem;
$featuredDealMobileRoundedCouponCodeLineHeight: 1.8rem;
$featuredDealMobileRoundedCouponCodeFontWeight: 600;
$featuredDealMobileRoundedCouponCodeLetterSpacing: 0;
$featuredDealsButtonMargin: 4.2rem 0 0;
$featuredDealsButtonBorder: 2px solid #bac5d2;
$featuredDealsButtonColor: #0b1f66;
$featuredDealsButtonBackground: $brandWhite;
$featuredDealsButtonPadding: 1.3rem .8rem;
$featuredDealsButtonHoverBackground: $brandWhite;
$featuredDealsButtonHoverColor: #0b1f66;

// Desktop
$featuredDealDesktopBorder: 1px solid $brandLightGray2;
$featuredDealDesktopRebateBackground: $brandLightGray4;
$featuredDealDesktopRebateHeight: 7.5rem;
$featuredDealDesktopPillWidth: 10rem;
$featuredDealDesktopPillBottom: 7rem;
$featuredDealDesktopRebateOldValueFontSize: 1.3rem;
$featuredDealDesktopRebateNewValueFontSize: 1.4rem;
$featuredDealDesktopRebateNewValueDisplay: inline-block;
$featuredDealDesktopRebateTierNameColor: $brandBlue4;
$featuredDealDesktopRebateTierNameFontSize: 1.4rem;
$featuredDealDesktopCouponCodeLetterSpacing: 0;
$featuredDealDesktopCouponCodeWrapColor: $brandDarkerBlue;
$featuredDealDesktopCouponCodeFontSize: 1.3rem;
$featuredDealDesktopCouponCodeLineHeight: 1.8rem;
$featuredDealDesktopCouponCodeFontWeight: 600;
$featuredDealDesktopRebateOldValueLineHeight: 1.8rem;
$featuredDealDesktopExpireFontWeight: 500;
$featuredDealDesktopFavoriteIconWidth: 2.1rem;
$featuredDealDesktopFavoriteIconHeight: 1.8rem;
$featuredDealDesktopFavoriteIconWrapPositionRight: 8px;
$featuredDealDesktopFavoriteIconWrapPositionTop: 6px;

/* Explore Categories */
$exploreCategoriesSectionPadding: (small: 4rem 0 3.2rem);
$exploreCategoriesSectionTitleH2FontWeight: (small: 300);
$exploreCategoriesTitleDotDisplay: none;
$exploreCategoriesSectionBackground: (small: linear-gradient(180deg, $brandDarkerBlue 0%, #154067 100%));
$exploreCategoriesListGap: 3rem;
$exploreCategoriesItemLinkPadding: 0 3rem 0 0;
$exploreCategoriesItemLinkBackground: $brandBlue4;
$exploreCategoriesItemLinkFontSize: 1.4rem;
$exploreCategoriesItemLinkLineHeight: 2rem;
$exploreCategoriesItemLinkLetterSpacing: 2.7px;
$exploreCategoriesItemLinkTextTransform: uppercase;
$exploreCategoriesIconBeforeFontFamily: 'delta_categories';
$exploreCategoriesIconBeforeColor: #024;
$exploreCategoriesIconBeforeDepartmentStoresContent: '\e818';
$exploreCategoriesIconBeforeComputersElectronicContent: '\e805';
$exploreCategoriesIconBeforeWomensClothingContent: '\e820';
$exploreCategoriesIconBeforeMensClothingContent: '\e813';
$exploreCategoriesIconBeforeBeautyScincareContent: '\e803';
$exploreCategoriesIconBeforeShoesContent: '\e817';
$exploreCategoriesIconBeforeSportsOutdoorsContent: '\e81a';
$exploreCategoriesIconBeforeFlowersGiftsContent: '\e808';
$exploreCategoriesIconBeforeBabiesKidsToysContent: '\e802';
$exploreCategoriesIconBeforeHomeKitchenContent: '\e80f';
$exploreCategoriesIconBeforeHealthWellnessContent: '\e80e';
$exploreCategoriesIconBeforeToolsAutoPetsContent: '\e81d';
$exploreCategoriesIconBeforeDepartmentStoresPositionTop: 29%;
$exploreCategoriesIconBeforeDepartmentStoresPositionLeft: 16%;
$exploreCategoriesIconBeforePositionTop: 29%;

/* Testimonials */
$testimonialsSectionBackground: (small: #11172b);
$testimonialsTextAlign: center;
$testimonialsIconBeforeBackground: url('~org/assets/images/icons/user_icon.svg') no-repeat center;
$testimonialsIconBeforeWidth: 100%;
$testimonialsIconBeforeHeight: 100%;
$testimonialsIconBorder: 1px solid $brandWhite;
$testimonialsIconBorderRadius: 50%;
$testimonialsIconBeforeBackground: no-repeat center/100% url('~org/assets/images/icons/account.svg');
$testimonialsIconHeight: 8rem;
$testimonialsIconWidth: 8rem;
$testimonialsCarouselTextColor: #fff;
$testimonialsCarouselIconHeight: 5.5rem;
$testimonialsCarouselIconWidth: 5.5rem;
$testimonialsCarouselIconBeforeContent: '';
$testimonialsCarouselIconBeforeBackground: url('~org/assets/images/icons/user_icon.svg') no-repeat center;
$testimonialsCarouselSectionTitleMargin: (small: 0 0 4rem 0);
$testimonialsCarouselTextFontSize: 1.7rem;
$testimonialsCarouselTextLineHeight: 3.4rem;
$testimonialsCarouselItemsMargin: (small: 0 auto);
$testimonialsCarouselItemsMaxWidth: (small: 768px);
$testimonialsCarouselDotWidth: 1.2rem;
$testimonialsCarouselDotHeight: 1.2rem;
$testimonialsCarouselDotBackground: rgba(231, 231, 231, .5);
$testimonialsCarouselDotBoxShadow: none;
$testimonialsCarouselDotActiveBackground: $brandRed3;
$testimonialsStaticSectionBackground: (small: url('~org/assets/images/palma_bg.jpg') no-repeat center/cover);
$testimonialsStaticItemsBackground: #fff;
$testimonialsStaticListPadding: 4rem 2.5rem 3rem;
$testimonialsStaticTextFontSize: 1.6rem;
$testimonialsStaticTextLineHeight: 3rem;
$testimonialsStaticItemsPadding: 4rem 2.5rem 3rem 2.5rem;
$testimonialsStaticSectionPadding: (small: 5rem 2rem 10rem 2rem);
$testimonialsStaticTextPseudoContent: '';
$testimonialsStaticItemsTextBeforeBackgroundImage: url('~org/assets/images/icons/quotation_close.png');
$testimonialsStaticItemsTextAfterBackgroundImage: url('~org/assets/images/icons/quotation_open.png');
$testimonialsStaticTextPseudoWidth: 4rem;
$testimonialsStaticTextPseudoHeight: 4rem;
$testimonialsStaticItemPosition: relative;
$testimonialsStaticItemPadding: 2rem 3.5rem;
$testimonialsStaticTextPseudoPosition: absolute;
$testimonialsStaticTextPseudopositionTop: 0;
$testimonialsStaticItemsTextBeforeRight: 0;
$testimonialsStaticItemsTextAfterLeft: 0;
$testimonialsStaticSectionH2FontSize: (small: 2.4rem);
$testimonialsStaticSectionH2LineHeight: (small: 2.6rem);

/* Gift Guide */
$giftGuideBackground: (small: #f6f6f7, medium: unset);
$giftGuideInnerBackground: (small: (), medium: #f6f6f7);
$giftGuideItemWrapBorder: 1px solid #e6eaf2;
$giftGuideItemMerchantLogoAfterBorderRadius: 0;
$giftGuideItemMerchantLogoAfterBorder: 1px solid #e6eaf2;
$giftGuideItemOfferDetailsFontSize: 1.3rem;
$giftGuideItemOfferDetailsLineHeight: 1.8rem;
$giftGuideItemRebateWrapBackground: #e6eaf2;
$giftGuideItemRebateWrapHeight: 4.2rem;
$giftGuideItemRebateWrapFontSize: 1.3rem;
$giftGuideItemRebateWrapTextAlign: center;
$giftGuideItemInlineRebateFontSize: 1.3rem;
$giftGuideItemInlineRebateLineHeight: 2rem;
$giftGuideItemElevationOldValueFontSize: 1.3rem;
$giftGuideButtonPadding: 1.3rem .8rem;
$giftGuideItemRebatePrefixFontWeight: 600;
$giftGuideItemFavoriteIconHeight: 1.4rem;
$giftGuideItemTieredPrefixDisplay: none;
$giftGuideItemCopyWrapMinHeight: 6.3rem;
$giftGuideItemPromoCodeWrapColor: $brandDarkerBlue;
$giftGuideItemPromoCodeFontSize: 1.2rem;
$giftGuideItemPromoCodeFontWeight: 600;
$giftGuideItemPromoCodeLetterSpacing: .4px;
$giftGuideItemExpirationDateFontSize: 1rem;
$giftGuideItemExpirationDateLineHeight: 1.5rem;
$giftGuideItemExpirationDateLetterSpacing: .4px;
$giftGuideItemExpirationDateMargin: -.5rem 0;

/* Sticky Footer */
// Mobile
$stickyFooterMobileBackground: $brandDarkerBlue;
$stickyFooterMobileContentBorderTop: ();
$stickyFooterMobileContentBorderBottom: ();
$stickyFooterMobileMerchantNameFontSize: 2.4rem;
$stickyFooterMobileMerchantNameLineHeight: 2.6rem;
$stickyFooterMobileMerchantNameFontWeight: 300;
$stickyFooterMobileMerchantRebateFontSize: 1.3rem;
$stickyFooterMobileMerchantRebateLineHeight: 1.4rem;
$stickyFooterMobileRolloverCopyFontSize: 1.3rem;
$stickyFooterMobileRolloverCopyLineHeight: 1.8rem;
$stickyFooterMobileRolloverCopyFontWeight: 300;
$stickyFooterMobileUseCodeFontSize: 1.3rem;
$stickyFooterMobileUseCodeLineHeight: 1.8rem;
$stickyFooterMobileCodeFontSize: 1.3rem;
$stickyFooterMobileCodeFontWeight: 600;
$stickyFooterMobileCodeLetterSpacing: 0;
$stickyFooterMobileShopNowLineHeight: 1.9rem;
$stickyFooterMobileShopNowLetterSpacing: .3px;
$stickyFooterMobileShopNowTextTransform: capitalize;
$stickyFooterMobileShopNowFontWeight: 600;
$stickyFooterMobileShopNowAfterContent: '\f105';
$stickyFooterMobileShopNowAfterFontFamily: 'fontello', serif;
$stickyFooterMobileShopNowAfterFontSize: 2rem;
$stickyFooterMobileShopNowAfterFontWeight: 400;
$stickyFooterMobileContentBorderBottom: 1px solid #798292;
$stickyFooterMobileContentPadding: 3rem 0 7.4rem;
// Desktop
$stickyFooterDesktopContentColor: $brandDarkerBlue;
$stickyFooterDesktopDisclaimerColor: #818181;
$stickyFooterDesktopDisclaimerMargin: .5rem 0 0;
$stickyFooterDesktopCodeTextTransform: uppercase;
$stickyFooterDesktopCodeFontWeight: 600;
$stickyFooterDesktopBoxShadow: 0 -2px 4px 0 rgba(0, 0, 0, .15);
$stickyFooterDesktopBorderTop: 1px solid $brandLightGray2;
$stickyFooterDesktopControlColor: #818a98;
$stickyFooterDesktopControlBeforeFontFamily: 'fontello';
$stickyFooterDesktopControlBeforeContent: '\2715';
$stickyFooterDesktopControlBeforeFontSize: 2rem;
$stickyFooterDesktopControlBeforeFontWeight: 700;
$stickyFooterDesktopControlBeforeCollapsedFontSize: 3rem;
$stickyFooterDesktopControlBeforeCollapsedFontWeight: 400;
$stickyFooterDesktopControlCollapsedBackground: $brandRed3;
$stickyFooterDesktopControlCollapsedColor: $brandWhite;
$stickyFooterDesktopControlBeforeCollapsedContent: '\f106';
$stickyFooterDesktopControlBorder: 1px solid $brandLightGray2;
$stickyFooterDesktopControlWidth: 4.4rem;
$stickyFooterDesktopControlBeforePadding: .6rem 1rem .6rem 1.2rem;
$stickyFooterDesktopControlBoxShadow: 0 -2px 2px 0 rgba(0, 0, 0, .15);
$stickyFooterDesktopControlPositionTop: -3.2rem;
$stickyFooterDesktopButtonWrapMinWidth: ();
$stickyFooterDesktopButtonPadding: 1.05rem;
$stickyFooterDesktopButtonWrapMargin: 0 1rem 0 0;
$stickyFooterDesktopButtonMinWidth: 15.5rem;
$stickyFooterDesktopButtonWhiteSpace: nowrap;
$stickyFooterDesktopOfferWrapTextAlign: center;
$stickyFooterDesktopOfferWrapWidth: 70%;
$stickyFooterDesktopOfferWrapMaxWidth: 65rem;
$stickyFooterDesktopContentJustifyContent: space-between;
$stickyFooterDesktopWrapBackground: $brandDarkerBlue;

/* Sticky CTA Bar */
// VHP
$stickyCtaBarVhpContentFlexDirection: (small: column, medium: row);
$stickyCtaBarVhpWrapBackground: $brandBlue;
$stickyCtaBarVhpBackgroundColor: (small: $brandBlue, medium: $brandBlue);
$stickyCtaBarVhpPadding: 2.4rem 0;
$stickyCtaBarVhpHeaderFontSize: 2.7rem;
$stickyCtaBarVhpHeaderLineHeight: 3.4rem;
$stickyCtaBarVhpHeaderFontWeight: 400;
$stickyCtaBarVhpHeaderColor: $brandWhite;
$stickyCtaBarVhpHeaderMargin: (small: 1rem 0 2rem, medium: 1rem 0);
$stickyCtaBarVhpHeaderTextAlign: center;
$stickyCtaBarVhpButtonFontSize: 1.4rem;
$stickyCtaBarVhpButtonWidth: (small: 38rem, medium: 20rem);
$stickyCtaBarVhpButtonPadding: (small: 1.85rem 0, medium: 1.85rem 0);
$stickyCtaBarVhpButtonMargin: (small: 0 0 1rem, medium: 1rem 0 1rem 2.5rem);
$stickyCtaBarVhpButtonBackground: $brandRed3;
$stickyCtaBarVhpButtonLineHeight: 1;

/* InfoBar */
// Slider
$infoBarSliderOfferBackground: #3d4558;
$infoBarSliderOfferDOTWBackground: #154067;
$infoBarSliderOfferBorderRadius: 4px 4px 0 0;
$infoBarSliderOfferLabelTextAlign: center;
$infoBarSliderOfferLabelLetterSpacing: 1.7px;
$infoBarSliderOfferWrapBorder: 1px solid #e6eaf2;
$infoBarSliderFavoriteIconRight: .9rem;
$infoBarSliderFavoriteIconTop: .9rem;
$infoBarSliderFavoriteIconWidth: 1.7rem;
$infoBarSliderFavoriteIconHeight: 1.7rem;
$infoBarSliderOfferItemCopyFontSize: 1.3rem;
$infoBarSliderOfferItemCopyTextAlign: center;
$infoBarSliderOfferRolloverCopyLineHeight: 1.4rem;
$infoBarSliderMerchantLogoMargin: 1.6rem auto 3.7rem;
$infoBarSliderOfferRebateBottom: .3rem;
$infoBarSliderOfferRebateLeft: .4rem;
$infoBarSliderOfferRebateHeight: 7.1rem;
$infoBarSliderOfferRebateBackground: #e6eaf2;
$infoBarSliderOfferPillBottom: 6.4rem;
$infoBarSliderOfferPillWidth: 10rem;
$infoBarSliderOfferItemExpireWidth: 145px;
$infoBarSliderOfferItemExpireLeft: calc(50% - #{$infoBarSliderOfferItemExpireWidth} / 2);
$infoBarSliderOfferItemExpireTextAlign: center;
$infoBarSliderOfferRebateBlockPadding: 1.3rem 1rem 1rem;
$infoBarSliderOfferCouponCodeWrapAlignItems: center;
$infoBarSliderOfferCouponCodeLabelFontSize: 1.3rem;
$infoBarSliderOfferWrapColor: #323232;
$infoBarSliderOfferItemExpireTop: 64%;
$infoBarSliderOfferItemExpireFontSize: 1.3rem;
$infoBarSliderOfferCouponCodeFontSize: 1.3rem;
$infoBarSliderOfferCouponCodeLetterSpacing: .3px;
$infoBarSliderOfferCouponCodeLineHeight: 1.7rem;
$infoBarSliderOfferCouponCodeFontWeight: 600;
$infoBarSliderOfferCouponCodeColor: $brandDarkerBlue;
$infoBarSliderOfferCouponCodeLabelLineHeight: 1.7rem;
$infoBarSliderOfferCouponCodeLabelColor: #3d4558;
// Accordion
$infoBarAccordionHeight: (small: 10rem, large: 10rem);
$infoBarAccordionColor: $brandDarkerBlue;
$infoBarAccordionOfferCTABackground: $brandLightGray2;
$infoBarAccordionDotwLabelBackground: #3d4558;
$infoBarAccordionOfferRebateColor: $rebateValueColor;
$infoBarAccordionOfferRebateValueFontSize: (medium: 1.3rem, large: 1.3rem, xlarge: 1.3rem, xxlarge: 1.3rem);
$infoBarAccordionDotwLabelFontSize: 1rem;
$infoBarAccordionDotwLabelLineHeight: 1.8rem;
$infoBarAccordionDotwLabelWidth: 12.5rem;
$infoBarAccordionDotwLabelLetterSpacing: 1px;
$infoBarAccordionDotwLabelHeight: 1.8rem;
$infoBarAccordionDotwLabelTop: -1.8rem;
$infoBarAccordionOfferTextLineHeight: (medium: 2.5rem);
$infoBarAccordionOfferRebateOldValueFontSize: (small: 1.2rem, medium: 1.3rem, large: 1.3rem, xlarge: 1.3rem, xxlarge: 1.3rem);
$infoBarAccordionOfferRebateFontSize: (small: 1.2rem, medium: 1.3rem, large: 1.3rem, xlarge: 1.3rem, xxlarge: 1.3rem);
$infoBarAccordionOfferRebateNewValueFontSize: (small: 1.2rem, medium: 1.3rem, large: 1.3rem, xlarge: 1.3rem, xxlarge: 1.3rem);
$infoBarAccordionOfferRebateOldValueAfterContent: ',';
$infoBarAccordionOfferRebateNewValueLetterSpacing: .46px;
$infoBarAccordionOfferCTATextFontSize: 1.5rem;
$infoBarAccordionOfferCTAColor: $brandDarkerBlue;
$infoBarAccordionOfferCTATextFontWeight: 600;
$infoBarAccordionOfferCTATextTransform: capitalize;
$infoBarAccordionOfferCTATextAfterContent: '\f105';
$infoBarAccordionOfferCTATextAfterFontFamily: 'fontello';
$infoBarAccordionOfferCTATextAfterColor: $brandRed3;
$infoBarAccordionOfferCTATextAfterFontSize: 2.2rem;
$infoBarAccordionSecondOfferBorderLeft: 4px solid $brandDarkerBlue;
$infoBarAccordionOfferCollapsedLargeWidth: 30.4rem;
$infoBarAccordionOfferExpandedXXLargeWidth: 30.4rem;
$infoBarAccordionOfferShortSummaryColor: $brandDarkerBlue;
$infoBarAccordionExpandIconBeforeContent: '+';
$infoBarAccordionExpandIconBeforeColor: $brandWhite;
$infoBarAccordionExpandIconBeforeBackground: #818a98;
$infoBarAccordionExpandIconBeforeWidth: 1.7rem;
$infoBarAccordionExpandIconBeforeHeight: 1.7rem;
$infoBarAccordionExpandIconBeforeDisplay: inline-block;
$infoBarAccordionExpandIconBeforeTextAlign: center;
$infoBarAccordionExpandIconBeforePadding: ();
$infoBarAccordionExpandIconBeforeFontSize: 1.9rem;
$infoBarAccordionExpandIconBeforeLineHeight: 1.7rem;
$infoBarAccordionCollapsedOfferRebateNewValueWhiteSpace: normal;
$infoBarAccordionCollapsedOfferRebateOldValueFontSize: (small: 1.3rem);
$infoBarAccordionCollapsedOfferRebateNewValueFontSize: (small: 1.3rem);
$infoBarAccordionSecondOfferCTABackground: $brandLightGray2;
$infoBarAccordionCollapsedOfferRebateOldValueDisplay: inline-block;
$infoBarAccordionOfferTextFontSize: (small: 1.2rem, medium: 1.4rem, xxlarge: 1.8rem);
$infoBarAccordionSecondOfferCTABoxShadow: ();
$infoBarAccordionCollapsedOfferRebateNewValueDisplay: inline;
$infoBarAccordionCollapsedOfferRebateFontSize: ();
$infoBarAccordionCollapsedOfferRebateValueFontSize: ();
$infoBarAccordionExpiredDotDisplay: none;
$infoBarAccordionSecondOfferCouponCodeAndRebateColor: #3d4558;
$infoBarAccordionSecondOfferCouponCodeColor: $brandDarkerBlue;
$infoBarAccordionOfferCouponCodeAndRebateFontSize: 1.5rem;
$infoBarAccordionOfferCouponCodeAndRebateColor: #3d4558;
$infoBarAccordionOfferCouponCodeFontWeight: 400;
$infoBarAccordionOfferCouponCodeFontSize: 1.5rem;
$infoBarAccordionOfferCouponCodeAndRebateDividerBorderRight: 1px solid #3d4558;
$infoBarAccordionOfferCouponCodeAndRebateCodeColor: $brandDarkerBlue;
$infoBarAccordionOfferCouponCodeAndRebateDividerMargin: 0 1rem -.3rem 1rem;
$infoBarAccordionOfferCouponCodeAndRebateDividerHeight: 1.7rem;

/* Earnings Snapshot */
$earningsSnapshotSectionBackground: (small: linear-gradient(180deg, #154067 0%, $brandDarkerBlue 100%));
$earningsSnapshotSummaryFontWeight: 500;
$earningsSnapshotSummaryLineHeight: 2.5rem;
$earningsSnapshotSummaryTextAlign: center;
$earningsSnapshotTransactionsMargin: 0 0 2rem;
$earningsSnapshotTransactionColor: $brandDarkerBlue;
$earningsSnapshotTransactionMerchantColor: #4470c3;
$earningsSnapshotTransactionMerchantFontWeight: 700;
$earningsSnapshotTransactionMerchantFontSize: 1.4rem;
$earningsSnapshotTransactionMerchantLineHeight: 2.4rem;
$earningsSnapshotButtonBackground: #bac5d2;
$earningsSnapshotButtonBorder: 2px solid #bac5d2;
$earningsSnapshotButtonColor: #0b1f66;
$earningsSnapshotButtonHeight: 5rem;
$earningsSnapshotButtonPadding: 1.2rem .8rem;
$earningsSnapshotTransactionInfoFontSize: 1.4rem;
$earningsSnapshotTransactionInfoTopFontWeight: 300;
$earningsSnapshotTransactionInfoTopLineHeight: 2.4rem;
$earningsSnapshotTransactionInfoBottomFontWeight: 600;
$earningsSnapshotTransactionInfoBottomLineHeight: 1.6rem;
$earningsSnapshotTransactionPadding: 1rem 2.5rem 1.3rem;
$earningsSnapshotTransactionInfoBottomDescriptionLetterSpacing: 1px;

/* Countdown */
$countdownLettersFontWeight: 700;

/* Letterboxes */
$heroCarouselMargin: ();
$heroCarouselSliderCounterColor: (smallMobile: $brandWhite, mediumMobile: $brandWhite,
smallTablet: (), largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselTileWithExtraPillPadding: 0;
$heroCarouselTileWithExtraPillImagePaddingTop: calc(286 / 268 * 100%);
$heroCarouselBorderRadius: (smallMobile: 0, mediumMobile: 0, smallTablet: 0,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselBorder: (smallMobile: 1px solid #e6eaf2, mediumMobile: 1px solid #e6eaf2,
smallTablet: (), largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselOfferDetailsMinHeight: 12rem;
$heroCarouselBreakPointOfferBottomDetailsContentMinHeights: (smallTablet: 10rem, largeMobileAndSmallDesktop: 10rem, largeTabletAndXLargeDesktop: 10rem);
$heroCarouselBreakPointOfferDetailsPadding: (smallMobile: 15px 5px 13px 118px, mediumMobile: 10px 10px 10px 118px, smallTablet: .5rem 3rem .3rem 1.7rem,
largeMobileAndSmallDesktop: .5rem 3rem .3rem 1.9rem, largeTabletAndXLargeDesktop: 1rem 16rem .3rem 1.5rem);
$heroCarouselBreakPointRebateFontSize: (smallMobile: 1.3rem, mediumMobile: 1.3rem, smallTablet: 1.3rem,
largeMobileAndSmallDesktop: 1.3rem, largeTabletAndXLargeDesktop: 1.3rem);
$heroCarouselBreakPointRebateLineHeight: (smallMobile: 1.8rem, mediumMobile: 1.8rem, smallTablet: 2.3rem,
largeMobileAndSmallDesktop: 2.3rem, largeTabletAndXLargeDesktop: 2.3rem);
$heroCarouselOldRebateElevationLetterSpacing: 0;
$heroCarouselBreakPointLogoPositionLeft: (smallMobile: -102px, mediumMobile: -102px);
$heroCarouselExtraRewardsPillLabelLetterSpacing: .46px;
$heroCarouselBreakPointShortCopyLetterSpacing: (smallMobile: 0, mediumMobile: 0, smallTablet: .4px,
largeMobileAndSmallDesktop: .4px, largeTabletAndXLargeDesktop: .4px);
$heroCarouselBreakPointLongCopyFontSize: (smallMobile: 1.3rem, mediumMobile: 1.3rem, smallTablet: 1.4rem,
largeMobileAndSmallDesktop: 1.4rem, largeTabletAndXLargeDesktop: 1.8rem);
$heroCarouselBreakPointLongCopyLineHeight: (smallMobile: 1.8rem, mediumMobile: 1.8rem, smallTablet: 2.2rem,
largeMobileAndSmallDesktop: 1.9rem, largeTabletAndXLargeDesktop: 2.7rem);
$heroCarouselCopyColor: (smallMobile: #3d4558, mediumMobile: #3d4558, smallTablet: $brandDarkerBlue,
largeMobileAndSmallDesktop: $brandDarkerBlue, largeTabletAndXLargeDesktop: $brandDarkerBlue);
$heroCarouselBreakPointRebateFontWeight: (smallMobile: 600, mediumMobile: 600, smallTablet: 600,
largeMobileAndSmallDesktop: 600, largeTabletAndXLargeDesktop: 600);
$heroCarouselBreakPointCouponCodeFontSize: (smallMobile: 1.3rem, mediumMobile: 1.3rem, smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 1.5rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselBreakPointCouponCodeLineHeight: (smallMobile: 1.8rem, mediumMobile: 1.8rem, smallTablet: 2rem,
largeMobileAndSmallDesktop: 2.4rem, largeTabletAndXLargeDesktop: 2.4rem);
$heroCarouselCouponCodeLabelColor: (smallMobile: #3d4558, mediumMobile: #3d4558, smallTablet: #3d4558,
largeMobileAndSmallDesktop: #3d4558, largeTabletAndXLargeDesktop: #3d4558);
$heroCarouselCouponCodeTextLetterSpacing: (smallMobile: 0, mediumMobile: 0, smallTablet: .4px,
largeMobileAndSmallDesktop: .4px, largeTabletAndXLargeDesktop: .4px);
$heroCarouselCouponCodeTextFontSize: (smallMobile: 1.3rem, mediumMobile: 1.3rem, smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 1.5rem, largeTabletAndXLargeDesktop: 1.5rem);
$heroCarouselCouponCodeFontWeight: 600;
$heroCarouselButtonFontSize: (smallMobile: 1.5rem, mediumMobile: 1.5rem, smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 1.2rem, largeTabletAndXLargeDesktop: 1.5rem);
$heroCarouselButtonMarginTop: (smallMobile: 0, mediumMobile: 0, smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 1.5rem, largeTabletAndXLargeDesktop: 1.5rem);
$heroCarouselInfobarMarketingTextJustifyContent: (smallMobile: flex-start, mediumMobile: flex-start, smallTablet: center,
largeMobileAndSmallDesktop: center, largeTabletAndXLargeDesktop: center);
$heroCarouselInfobarMarketingHeaderMargin: (smallMobile: 5rem 0 1rem, mediumMobile: 5rem 0 1rem, smallTablet: 1rem 0,
largeMobileAndSmallDesktop: 1rem 0, largeTabletAndXLargeDesktop: 1rem 0);
$heroCarouselInfobarMarketingTextFlexGrow: (smallMobile: unset, mediumMobile: unset, smallTablet: 1,
largeMobileAndSmallDesktop: 1, largeTabletAndXLargeDesktop: 1);
$heroCarouselInfobarMarketingHeaderFontSize: (smallMobile: 2.8rem, mediumMobile: 2.8rem, smallTablet: 2.8rem,
largeMobileAndSmallDesktop: 2.8rem, largeTabletAndXLargeDesktop: 3.5rem);
$heroCarouselInfobarMarketingHeaderLineHeight: (smallMobile: 3.6rem, mediumMobile: 3.6rem, smallTablet: 3.2rem,
largeMobileAndSmallDesktop: 3.2rem, largeTabletAndXLargeDesktop: 3.2rem);
$heroCarouselInfobarMarketingHeaderLetterSpacing: (smallMobile: 0, mediumMobile: 0, smallTablet: -.5px,
largeMobileAndSmallDesktop: -.5px, largeTabletAndXLargeDesktop: -.5px);
$heroCarouselInfobarMarketingHeaderFontWeight: 300;
$heroCarouselInfobarMarketingCTAPadding: (smallMobile: 1.3rem 0, mediumMobile: 1.3rem 0, smallTablet: 1.2rem 0,
largeMobileAndSmallDesktop: 1.12rem 0, largeTabletAndXLargeDesktop: 1.12rem 0);
$heroCarouselInfobarMarketingTextLetterSpacing: (smallMobile: 1px, mediumMobile: 1px, smallTablet: .22px,
largeMobileAndSmallDesktop: .22px, largeTabletAndXLargeDesktop: .22px);
$heroCarouselInfobarMarketingTextColor: (smallMobile: $brandDarkerBlue, mediumMobile: $brandDarkerBlue, smallTablet: #3d4558,
largeMobileAndSmallDesktop: #3d4558, largeTabletAndXLargeDesktop: #3d4558);
$heroCarouselInfobarMarketingTextPadding: (smallMobile: 0 1.5rem, mediumMobile: 0 1.5rem, smallTablet: 0 1.5rem,
largeMobileAndSmallDesktop: 0 1.5rem, largeTabletAndXLargeDesktop: 0 1.5rem);
$heroCarouselInfobarMarketingCountdownBackgrond: (smallMobile: #3d4558, mediumMobile: #3d4558, smallTablet: #3d4558,
largeMobileAndSmallDesktop: #3d4558, largeTabletAndXLargeDesktop: #3d4558);
$heroCarouselInfobarMarketingCountdownPrefixTextPadding: (smallMobile: 0 1rem 0 0, mediumMobile: 0 1rem 0 0, smallTablet: 0 .2rem 0 0,
largeMobileAndSmallDesktop: 0 .2rem 0 0, largeTabletAndXLargeDesktop: 0 .2rem 0 0);
$heroCarouselInfobarMarketingCountdownPrefixTextTransform: (smallMobile: uppercase, mediumMobile: uppercase, smallTablet: uppercase,
largeMobileAndSmallDesktop: uppercase, largeTabletAndXLargeDesktop: uppercase);
$heroCarouselInfobarMarketingCountdownLineHeight: (smallMobile: 1.3rem, mediumMobile: 1.3rem, smallTablet: 1.8rem,
largeMobileAndSmallDesktop: 1.8rem, largeTabletAndXLargeDesktop: 1.8rem);
$heroCarouselInfobarMarketingHeaderPadding: (smallMobile: 2.03rem .3rem, mediumMobile: 2.03rem .3rem, smallTablet: 1.4rem .5rem,
largeMobileAndSmallDesktop: 1rem .5rem, largeTabletAndXLargeDesktop: 1.4rem .5rem);
$heroCarouselInfobarMarketingCountdownCounterLetterSpacing: (smallMobile: .64px, mediumMobile: .64px, smallTablet: 1px,
largeMobileAndSmallDesktop: 1px, largeTabletAndXLargeDesktop: 1px);
$heroCarouselInfobarMarketingCountdownCounterBorderRight: 1px solid $brandBorder;
$heroCarouselInfobarMarketingCountdownCounterBeforeMargin: 0 .6rem;
$heroCarouselContainerMargin: (smallMobile: 0 0 5rem 0, mediumMobile: 0 0 5rem 0, smallTablet: 0 0 5rem 0,
largeMobileAndSmallDesktop: 0 0 3rem 0, largeTabletAndXLargeDesktop: 0 0 3rem 0);
$heroCarouselBreakPointHeights: (smallMobile: auto, mediumMobile: auto, smallTablet: 261px,
largeMobileAndSmallDesktop: 234px, largeTabletAndXLargeDesktop: 295px);
$heroCarouselBreakPointLeftImageWidths: (smallMobile: 420px, mediumMobile: 420px, smallTablet: 372px,
largeMobileAndSmallDesktop: 333px, largeTabletAndXLargeDesktop: 420px);
$heroCarouselItemPadding: (smallMobile: (), mediumMobile: (),
smallTablet: 1px, largeMobileAndSmallDesktop: 1px, largeTabletAndXLargeDesktop: 1px);
$heroCarouselLeftImageMargin: 0 0 .1rem;
$heroCarouselLeftImageHeight: 100%;
$heroCarouselBreakPointLeftImageHeights: (smallMobile: (), mediumMobile: (),
smallTablet: 100%, largeMobileAndSmallDesktop: 100%, largeTabletAndXLargeDesktop: 100%);
$heroCarouselBreakPointLeftImageMargin: (smallMobile: (), mediumMobile: (),
smallTablet: -1px, largeMobileAndSmallDesktop: -1px, largeTabletAndXLargeDesktop: -1px);
$heroCarouselDisclaimerBottomPosition: (smallMobile: 0, mediumMobile: 0, smallTablet: .3rem,
largeMobileAndSmallDesktop: .3rem, largeTabletAndXLargeDesktop: .7rem);
$heroCarouselDisclaimerColor: (smallMobile: #3d4558, mediumMobile: #3d4558, smallTablet: #999,
largeMobileAndSmallDesktop: #999, largeTabletAndXLargeDesktop: #999);
$heroCarouselDisclaimerPadding: (smallMobile: 0 10rem 0 0, mediumMobile: 0 10rem 0 0, smallTablet: 0 5rem 0 0,
largeMobileAndSmallDesktop: 0 5rem 0 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselButtonWidth: (smallMobile: 16rem, mediumMobile: 16rem, smallTablet: 20rem,
largeMobileAndSmallDesktop: 15rem, largeTabletAndXLargeDesktop: 20rem);
$heroCarouselArrowBackground: transparent;
$heroCarouselArrowFontSize: 3.5rem;
$heroCarouselArrowColor: #818a98;
$heroCarouselBreakPointDotBorderWidth: 1px;
$heroCarouselBreakPointDotBorderColor: (smallMobile: (), mediumMobile: (),
smallTablet: #818a9a, largeMobileAndSmallDesktop: #818a9a, largeTabletAndXLargeDesktop: #818a9a);
$heroCarouselBreakPointDotBackground: (smallMobile: (), mediumMobile: (), smallTablet: $brandWhite,
largeMobileAndSmallDesktop: $brandWhite, largeTabletAndXLargeDesktop: $brandWhite);
$heroCarouselBreakPointActiveDotBackground: (smallMobile: $brandWhite, mediumMobile: $brandWhite,
smallTablet: #818a9a, largeMobileAndSmallDesktop: #818a9a, largeTabletAndXLargeDesktop: #818a9a);
$heroCarouselBreakPointDotWidth: (smallMobile: 15px, mediumMobile: 15px, smallTablet: 10px,
largeMobileAndSmallDesktop: 10px, largeTabletAndXLargeDesktop: 10px);
$heroCarouselBreakPointDotHeight: (smallMobile: 15px, mediumMobile: 15px, smallTablet: 10px,
largeMobileAndSmallDesktop: 10px, largeTabletAndXLargeDesktop: 10px);
$heroCarouselBreakPointHoverDotBackground: (smallMobile: $brandWhite, mediumMobile: $brandWhite, smallTablet: #818a9a,
largeMobileAndSmallDesktop: #818a9a, largeTabletAndXLargeDesktop: #818a9a);
$heroCarouselDotsPositionBottom: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 3rem,
largeMobileAndSmallDesktop: 2rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselInfoBarMarketingContentBackground: (smallMobile: (), mediumMobile: (), smallTablet: linear-gradient(180deg, $brandWhite 0, $brandLightGray2 100%),
largeMobileAndSmallDesktop: linear-gradient(180deg, $brandWhite 0, $brandLightGray2 100%), largeTabletAndXLargeDesktop: linear-gradient(180deg, $brandWhite 0, $brandLightGray2 100%));
$heroCarouselInfobarMarketingContentBorder: (smallMobile: (), mediumMobile: (), smallTablet: 1px solid $brandLightGray2,
largeMobileAndSmallDesktop:1px solid $brandLightGray2, largeTabletAndXLargeDesktop: 1px solid $brandLightGray2);
$heroCarouselInfobarMarketingCTALineHeight: 1;
$heroCarouselInfobarMarketingCTAMargin: (smallMobile: 2rem 0 0, mediumMobile: 2rem 0 0, smallTablet: 0 0 1.5rem,
largeMobileAndSmallDesktop: 0 0 1.5rem, largeTabletAndXLargeDesktop: 0 0 1.5rem);
$heroCarouselInfobarMarketingHeaderColor: (smallMobile: (), mediumMobile: (),
smallTablet: #3d4558, largeMobileAndSmallDesktop: #3d4558, largeTabletAndXLargeDesktop: #3d4558);
$heroCarouselInfobarMarketingTextFontSize: (smallMobile: 1.5rem, mediumMobile: 1.5rem, smallTablet: 1.6rem,
largeMobileAndSmallDesktop: 1.6rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselInfobarMarketingHeaderBorderRadius: (smallMobile: 0, mediumMobile: 0, smallTablet: 0,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselInfobarMarketingCountdownPrefixTextFontWeight: (smallMobile: (), mediumMobile: (), smallTablet: 400,
largeMobileAndSmallDesktop: 400, largeTabletAndXLargeDesktop: 400);

// Infobar Marketing Tile
$infoBarMarketingTileMargin: 0 0 0 .4rem;
$infoBarMarketingHeaderColor: #3d4558;
$infoBarMarketingHeaderLetterSpacing: -.56px;
$infoBarMarketingContentTextAlign: (small: center);
$infoBarMarketingTextColor: #3d4558;
$infoBarMarketingButtonMargin: (small: auto auto 1rem 0, medium: 0 0 1rem, xlarge: 0 0 2rem);
$infoBarMarketingButtonWidth: 26rem;
$infoBarMarketingButtonPadding: 1.2rem;
$infoBarMarketingButtonLineHeight: 1;
$infoBarMarketingContentPadding: (small: 0 .5rem, xlarge: 0 2rem);
$infoBarMarketingHeaderLineHeight: (small: 2.4rem, xlarge: 3.2rem);
$infoBarMarketingTextFontSize: (small: 1.4rem, xlarge: 1.6rem);
$infoBarMarketingTextLineHeight: (small: 1.8rem, xlarge: 2.2rem);
$infoBarMarketingContentBackground: linear-gradient(180deg, $brandWhite 0, $brandLightGray2 100%);
$infoBarMarketingCountdownWrapBackground: #3d4558;
$infoBarMarketingCountdownWrapWidth: (small: calc(100% + 1rem), xlarge: calc(100% + 4rem));
$infoBarMarketingCountdownClockPrefixTextTransform: uppercase;
$infoBarMarketingCountdownClockPrefixFontSize: 1.1rem;
$infoBarMarketingCountdownClockPrefixLetterSpacing: .2px;
$infoBarMarketingHeaderMargin: (small: 0 0 1rem, xlarge: 0 0 1.5rem);
$infoBarMarketingCountdownDigitFontSize: 1.5rem;
$infoBarMarketingCountdownDigitFontWeight: 400;
$infoBarMarketingCountdownDigitAfterFontWeight: 400;
$infoBarMarketingCountdownDigitBeforeDisplay: inline-block;
$infoBarMarketingCountdownDigitBeforeBorderRight: 1px solid #9da6ab;
$infoBarMarketingCountdownDigitBeforeHeight: 2.3rem;
$infoBarMarketingCountdownDigitBeforePadding: 0 .3rem 0 .2rem;
$infoBarMarketingCountdownDigitBeforeMargin: 0 .5rem -.5rem 0;
$infoBarMarketingContentBorder: 1px solid #e8ecf2;

/* Recent Stores Flyout */
$recentStoresFlyoutBorderWidth: 2px 0 2px 2px;
$recentStoresFlyoutHeadBgColor: #002a50;
$recentStoresFlyoutH2Color: $brandWhite;
$recentStoresFlyoutH2FontSize: 1.4rem;
$recentStoresFlyoutH2FontWeight: 700;
$recentStoresFlyoutH2TextTransform: uppercase;

/* Adblock Warning */
$adBlockWarningIconHeight: 2.5rem;
$adBlockWarningIconWidth: 2.5rem;
$adBlockWarningIconContent: '!';
$adBlockWarningIconColor: $brandWhite;
$adBlockWarningIconFontWeight: 700;
$adBlockWarningIconFontSize: 2.1rem;
$adBlockWarningIconLineHeight: 2.5rem;
$adBlockWarningIconBorderRadius: 50%;
$adBlockWarningIconBackgroundColor: #3d4558;
$adBlockWarningIconMinWidth: 2.5rem;
$adBlockWarningMessageFontSize: 1.3rem;
$adBlockWarningMessageFontWeight: 400;
$adBlockWarningMessageInnerPadding: 0 2rem 0 1rem;
$adBlockWarningMessageInnerMaxWidth: 90rem;
$adBlockWarningMessageBackground: $brandLightGray3;
$adBlockWarningBackground: $brandLightGray3;
$adBlockWarningMessageJustifyContent: center;
$adBlockWarningMessagePadding: .65rem 1.5rem;
$adBlockWarningPadding: .6rem 0;
$adBlockWarningBorderBottom: 1px solid $brandWhite;

/* Form Live Validation */
$formLiveValidationErrorColor: $brandRed3;
$formLiveValidationInputColor: $brandGray6;
$formLiveValidationInputFontSize: (small: 1.2rem, medium: 1.2rem);
$formLiveValidationInputErrorFocusOutlineColor: $formLiveValidationErrorColor;
$formLiveValidationInputContainerMargin: 0;
$formLiveValidationButtonContainerJustifyContent: flex-start;
$formLiveValidationButtonContainerMargin: 2.5rem 0;
$formLiveValidationButtonWidth: (small: 100%, large: 30rem);
$formLiveValidationButtonHeight: 4rem;
$formLiveValidationButtonFontSize: 1.4rem;
$formLiveValidationCheckboxMargin: 2rem 0 5rem 0;
$formLiveValidationCheckboxCheckedBackgroundColor: $brandGray6;
$formLiveValidationCheckboxCheckedBoxShadow: none;
$formLiveValidationCheckboxLabelBorder: none;
$formLiveValidationCheckboxCheckedBorder: none;
$formLiveValidationCheckboxCheckedBorderRadius: 0;
$formLiveValidationCheckboxLabelWidth: 2.2rem;
$formLiveValidationCheckboxLabelHeight: 2.2rem;
$formLiveValidationCheckboxCheckedIconBorder: 2px solid $brandWhite;
$formLiveValidationCheckboxLabelBackgroundColor: $brandGray6;
$formLiveValidationCheckboxLabelFontSize: 1.3rem;
$formLiveValidationCheckboxLabelSupFontSize: .8rem;
$formLiveValidationCheckboxLabelFontFamily: $brandFontFamily;
$formLiveValidationCheckboxLabelLineHeight: 1.6rem;
$formLiveValidationCheckboxLabelBoxShadow: none;
$formLiveValidationTooltipButtonBeforeFontSize: 1.2rem;
$formLiveValidationTooltipButtonBeforeHeight: 1.2rem;
$formLiveValidationTooltipButtonBeforeWidth: 1.2rem;
$formLiveValidationTooltipButtonBeforeLineHeight: 1.3rem;
$formLiveValidationTooltipButtonBeforeBorder: 1px solid $brandBlue4;
$formLiveValidationTooltipButtonBeforeColor: $brandBlue4;
$formLiveValidationTooltipHintColor: $brandWhite;
$formLiveValidationTooltipHintBackground: $brandDarkerBlue;
$formLiveValidationTooltipHintBorder: none;
$formLiveValidationTooltipTouchDeviceButtonFontWeight: 700;
$formLiveValidationTooltipTouchDeviceButtonColor: $brandBlue4;
$formLiveValidationShowOrHidePasswordColor: $brandBlue4;
$formLiveValidationShowOrHidePasswordFontSize: 1.2rem;
$formLiveValidationShowOrHidePasswordFontweight: bold;
$formLiveValidationFieldErrorColor: $errorColor;
$formLiveValidationFieldErrorFontSize: 1.2rem;

/* Featured Offers */
$featuredOffersLogoSectionTitleTextAlign: center;
$featuredOffersLogoItemTileWidth: 15.4rem;
$featuredOffersLogoViewAllLinkFontSize: 1.4rem;
$featuredOffersLogoRebateBackground: $brandLightGray4;
$featuredOffersLogoRebateOldValue: 1.1rem;
$featuredOffersLogoRebateNewValueLineHeight: 1.6rem;
$featuredOffersLogoPillPositionBottom: (small: 5.8rem, medium: 6.2rem);

/* First Favorites Modal */
$addToFavoriteFirstTimePopUpModalPositionTop: (small: 0, medium: 20%);
$addToFavoriteFirstTimePopUpTitleColor: (small: $brandWhite, medium: $brandPrimaryColor);
$addToFavoriteFirstTimePopUpModalBackground: (small: $brandPrimaryColor, medium: $brandWhite);
$addToFavoriteFirstTimePopUpTextFontSize: 1.6rem;
$addToFavoriteFirstTimePopUpTextLineHeight: 2.1rem;
$addToFavoriteFirstTimePopUpTextMargin: (small: 2rem 0 3rem, medium: 2rem 0 3rem);
$addToFavoriteFirstTimePopUpTextColor: (small: $brandWhite, medium: $brandDarkGray);
$addToFavoriteFirstTimePopUpButtonPadding: 1rem 3rem;
$addToFavoriteFirstTimePopUpButtonWidth: auto;
$addToFavoriteFirstTimePopUpButtonFontSize: 1.2rem;
$addToFavoriteFirstTimePopUpButtonFontWeight: 400;
$addToFavoriteFirstTimePopUpButtonBackground: linear-gradient(0deg, $brandRed 0, $brandRed 100%);
$addToFavoriteFirstTimePopUpButtonLetterSpacing: .8px;
$addToFavoriteFirstTimePopUpButtonLineHeight: 1;
$addToFavoriteFirstTimePopUpLinkColor: (small: $brandWhite, medium: $brandDarkGray);
$addToFavoriteFirstTimePopUpLinkTextDecoration: underline;
$addToFavoriteFirstTimePopUpModalCloseColor: (small: $brandWhite, medium: $brandPrimaryColor);
$addToFavoriteFirstTimePopUpTitleFontWeight: 500;

/* Header Holiday Image */
$holidayImageMargin: (small: -2.3rem 0 0 0, medium: -4rem 0 0 0);

/* Holiday Border */
$holidayWrapBackground: $brandDarkerBlue;

/* Form Tooltip */
$tooltipFormHintCloseButtonAfterColor: $brandWhite;

/* Button Overlay */
$buttonOverlayContentBackground: url('~org/assets/images/button_overlay_background.png') no-repeat center;
$buttonOverlayHeaderFontSize: 3.4rem;
$buttonOverlayHeaderFontWeight: 400;
$buttonOverlayHeaderMargin: .5rem -4rem 1rem;
$buttonOverlayHeaderLetterSpacing: -.1rem;
$buttonOverlayMottoColor: $brandDarkerBlue;
$buttonOverlayMottoFontSize: 1.4rem;
$buttonOverlayMottoLetterSpacing: 2.7px;
$buttonOverlayMottoTextTransform: uppercase;
$buttonOverlayTextFontSize: 1.4rem;
$buttonOverlayTextLineHeight: 2.1rem;
$buttonOverlayTextMarginBottom: 2.5rem;
$buttonOverlayRatingTextFontSize: 1.2rem;
$buttonOverlayRatingTextTextTransform: capitalize;
$buttonOverlayRatingTextLetterSpacing: .4px;
$buttonOverlayRatingTextLineHeight: 1.8rem;
$buttonOverlayButtonFontSize: 1.4rem;
$buttonOverlayScrollbarTrackPieceBackground: #e6eaf2;
$buttonOverlayScrollbarThumBackground: #798292;
$buttonOverlayBlockPadding: 0 8rem;
$buttonOverlayMerchantLogosMargin: 0 -1rem 2.5rem;
$buttonOverlayHiwHintsColor: $brandWhite;
$buttonOverlayBlockTwoPositionTop: 68rem;
$buttonOverlayBlockThreeHeaderColor: $brandWhite;
$buttonOverlayHiwHintsMargin: 25rem 0 2rem 0;
$buttonOverlayHiwHintsSubheaderFontWeight: 400;
$buttonOverlayHiwHintsBorder: 1px solid $brandBlue5;
$buttonOverlayHiwHintsSubheaderMargin: 1.5rem 0;
$buttonOverlayHiwHintsBlockOneTextMarginTop: 2.3rem;

/* Home Page */
$homePageMhpTopWrapBackground: (small: $brandDarkerBlue);
$homePageMhpTopWrapPadding: (small: 2rem 0 0, medium: 0);

/* Carousel */
$carouselDotBackground: unset;
$carouselDotBoxShadow: inset 0 0 0 .1rem #818a9a;
$carouselDotActiveBackground: #818a9a;
$carouselInvertedDotActiveBackground: #818a9a;
$carouselInvertedDotBoxShadow: inset 0 0 0 .1rem  #818a9a;
$carouselInvertedDotActiveBoxShadow: none;
$carouselDotWidth: 1rem;
$carouselDotHeight: 1rem;
$carouselCounterNumberFontSize: 1.5rem;
$carouselCounterNumberFontWeight: bold;
$carouselCounterColor: $brandDarkerBlue;
$carouselCounterArrowColor: $brandBlue5;
$carouselCounterArrowFontSize: 2.8rem;
$carouselCounterNumberPositionTop: .2rem;
$carouselViewportArrowColor: $brandBlue5;

/* Product Details */
$prodImagesFloat: (medium: none, large: left);

/* DNT Mobile Warning */
$dntMobileWarningWrapOldSearchModalPadding: 0 1.2rem .5rem 1.7rem;
$dntMobileWarningWrapBorderColor: #ea8a1a;
$dntMobileWarningTextFontSize: 1.3rem;
$dntMobileWarningTextLineHeight: 1.8rem;
$dntMobileWarningWrapBorderRadius: 2px;
$dntMobileWarningTextTextAlign: left;
$dntMobileWarningIconColor: #ea8a1a;
$dntMobileWarningIconWidth: 2.3rem;
$dntMobileWarningIconHeight: 2.3rem;
$dntMobileWarningIconFontSize: 2.3rem;
$dntMobileWarningIconPositionLeft: -1.5rem;
$dntMobileWarningTextMargin: 0 0 0 -1rem;
$dntMobileWarningWrapOldSearchDropdownMargin: 0 .5rem .5rem;
$dntMobileWarningWrapOldSearchDropdownMatchPadding: .5rem 5rem .5rem .2rem;
$dntMobileWarningTextOldSearchDropdownMargin: 0 1rem 0 0;
$dntMobileWarningIconOldSearchDropdownPositionLeft: 0;
$dntMobileWarningContentOldSearchDropdownFlexDirection: row-reverse;
$dntMobileWarningIconMargin: .7rem 0 0;
$dntMobileWarningTextOldSearchDropdownColor: #3d4558;
$dntMobileWarningTextColor: $brandWhite;
