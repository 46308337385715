@import '~org/styles/variables';

.mn_headerSecondaryBar {
  pointer-events: none;
  position: fixed;
  top: 100%;
  right: -40rem;
  width: 100%;
  max-height: calc(100vh - 17rem);
  background: $brandDarkerBlue;
  transform: translate3d(0, 0, 0);
  padding: 0 2.5rem 11rem;
  overflow: auto;
  transition: all .5s;
  opacity: 0;

  &:before {
    content: '';
    position: absolute;
    left: 1rem;
    right: 1rem;
    top: 0;
    height: .1rem;
    background: $brandBlue5;
  }

  .mn_open & {
    pointer-events: auto;
    transform: translate3d(-40rem, 0, 0);
    opacity: 1;
  }

  @media #{$medium-screen} {
    width: 40rem;
    opacity: 1;

    .mn_open & {
      max-height: none;
      overflow-y: hidden;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .5);
      clip-path: inset(0 -10px 0 -10px);
    }
  }
}

/* Full page height navigation background */
@media #{$medium-screen} and #{$less-than-xlarge-screen} {
  body {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -40rem;
      z-index: 3;
      width: 40rem;
      background: $brandDarkerBlue;
      transition: all .5s;
    }
  }

  html.mn_open {
    overflow-x: hidden;

    body {
      overflow: visible;

      &:before {
        transform: translateX(-40rem);
        visibility: visible;
        max-height: none;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .5);
      }
    }
  }
}

@media #{$less-than-medium-screen} {
  .mn_MHP {
    .mn_headerSecondaryBar {
      max-height: calc(100vh - 8.5rem);

      &:after {
        content: '';
        position: absolute;
        left: 1rem;
        right: 1rem;
        top: 12.5rem;
        height: .1rem;
        background: $brandBlue5;
      }
    }
  }
}
