@import '~core/styles/mixins';
@import '~org/styles/variables';

.mn_headerMainBar {
  padding: 2rem 0;

  .mn_contentSection {
    max-width: $xxlarge;
    height: 4.5rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    margin: 0 auto;
  }

  .mn_searchAndNavWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 57.5rem;
    margin-left: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .mn_searchBar {
      padding: 0;

      .mn_searchForm {
        .mn_searchInput {
          background: 0 0;
          border-bottom: 1px solid $brandGray6;
          color: $brandWhite;
          font-size: 2rem;
          font-weight: 300;
          height: 4.5rem;
          border-radius: 0;

          &:focus {
            background: $brandBlue6;
            border: 2px solid $brandGray7;
            border-bottom: 2px solid $brandWhite;
            border-radius: 4px;
          }
        }

        .mn_dropDownWrap {
          left: -8rem;
          right: -8rem;
          top: 6rem;
          margin: 0;
          box-shadow: 0 2px 4px 0 $brandLightBlue3;
        }
      }

      .mn_searchSubmit {
        position: absolute;
        top: 0;
        right: .5rem;
        background: 0 0;
        border: none;
        height: 4.5rem;
        line-height: 4.5rem;
        font-size: 2.2rem;
        width: 3rem;
        padding: .2rem .5rem 0 0;
      }
    }
  }

  .mn_headerTopNavigation {
    display: flex;
    flex: 0 0 auto;
    padding: 0;
    margin: 0 2rem 0 10%;
    align-items: center;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      border-right: .75px solid rgba(212, 212, 208, .5);
      top: -.2rem;
      bottom: -.2rem;
      right: -1.5rem;
    }

    .mn_searchTrigger {
      outline: none;

      &[aria-expanded='true'] {
        &:before {
          content: '\e90c';
        }
      }
    }
  }

  .mn_navigationButtonsWrap {
    display: flex;
  }

  @media #{$medium-screen} {
    padding: 4rem 0;
  }

  @media #{$large-screen} {
    .mn_contentSection {
      padding: 0 3rem;
    }
  }
}
