@import '~core/styles/mixins';
@import '~org/styles/variables';

.mn_mainNavigationTOF {
  width: 34.5rem;
  margin-right: 2.5rem;

  /* Navigation drop down */
  .mn_navigationDropdownWrapper {
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: -2rem;

    .mn_navigationDropdown {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transform: translate3d(0, -10px, 0);
      transition: all .3s ease-in-out;
      position: absolute;
      top: 4rem;
      left: -16rem;
      width: 96rem;
      background: $brandDarkerBlue;
      padding: 2rem 3rem 9rem;
      z-index: 2;
      text-align: left;
      display: flex;
      justify-content: space-between;

      .mn_allStoresLink {
        display: block;
        padding: 0;
        height: 4rem;
        line-height: 4rem;
        margin: 3.1rem 0;
        font-size: 1.3rem;
        letter-spacing: 1.24px;
        text-decoration: none;
        background: $brandRed3;
      }
    }

    .mn_navigationDropdownTrigger {
      display: block;
      background: none;
      border: none;
      border-bottom: 1px solid $brandGray6;
      color: $brandWhite;
      font-size: 2.4rem;
      font-weight: 300;
      line-height: 4rem;
      height: 4.5rem;
      text-align: left;
      padding: .4rem 5rem .4rem .2rem;
      letter-spacing: -1.5px;

      .mn_navArrow {
        position: absolute;
        top: 0;
        right: 0;

        &:before {
          font-family: 'fontello', serif;
          content: '\f107';
          font-size: 3.6rem;
          position: absolute;
          top: .4rem;
          right: 2rem;
          height: 1.2rem;
          width: 2rem;
          color: $brandRed4;
        }
      }
    }

    &.mn_focused {
      .mn_navigationDropdown {
        opacity: 1;
        transform: translate3d(0, 10px, 0);
        pointer-events: all;
        visibility: visible;
      }

      .mn_navigationDropdownTrigger {
        border-bottom: 3px solid $brandRed4;

        .mn_navArrow:before {
          content: '\f106';
        }
      }
    }
  }

  /* Common navigation lists styles */
  h2 {
    font-size: 2.5rem;
    font-weight: 500;
    margin: 0 0 2rem;
    color: $brandWhite;
  }

  .mn_navDropdownMenu {
    list-style: none;
    width: 27.5rem;
    font: normal 1.4rem/2.1rem $brandFontFamily;
    margin: 0 -1rem;
    padding: 0;
    border: none;

    li {
      margin: 0;

      & > a {
        display: block;
        width: fit-content;
        text-decoration: none;
        text-transform: capitalize;
        padding: .65rem 1rem;
        color: $brandWhite;
        letter-spacing: .34px;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .mn_leftMainNavPanelSect {
    flex: 0 0 27rem;
    border-right: $brandBlue5 solid 1px;

    .mn_navCategoriesTOFSection h2 {
      text-transform: none;
    }

    .mn_navCategoriesTOF {
      &:hover,
      &:focus {
        background: #282f45;
      }
    }
  }

  .mn_middleMainNavPanelSect {
    flex: 0 0 30rem;
    border-right: $brandBlue5 solid 1px;
    padding-bottom: 16rem;

    .mn_navOffersTOF h2 {
      text-transform: none;
    }
  }

  .mn_rightMainNavPanelSect {
    flex: 0 0 28rem;
    padding-bottom: 16rem;
  }

  .mn_bottomMainNavPanelSect {
    width: 53rem;
    height: 18rem;
    position: absolute;
    bottom: 3rem;
    left: 33rem;
  }
}
