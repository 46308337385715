/* stylelint-disable */

@font-face {
  font-family: 'delta_categories';
  src: url('../font/delta_categories.eot?21323135');
  src: url('../font/delta_categories.eot?21323135#iefix') format('embedded-opentype'),
       url('../font/delta_categories.woff2?21323135') format('woff2'),
       url('../font/delta_categories.woff?21323135') format('woff'),
       url('../font/delta_categories.ttf?21323135') format('truetype'),
       url('../font/delta_categories.svg?21323135#delta_categories') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "delta_categories";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  margin-left: .2em;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-appliances:before { content: '\e801'; }
.icon-babies-kids-toys:before { content: '\e802'; }
.icon-beauty-skincare:before { content: '\e803'; }
.icon-bopuis:before { content: '\e804'; }
.icon-computers-electronics:before { content: '\e805'; }
.icon-domain-names:before { content: '\e806'; }
.icon-entertainment-events:before { content: '\e807'; }
.icon-flowers-gifts:before { content: '\e808'; }
.icon-food-dining:before { content: '\e809'; }
.icon-free-ship:before { content: '\e80a'; }
.icon-furniture-decor:before { content: '\e80b'; }
.icon-gift-cards:before { content: '\e80c'; }
.icon-gifts:before { content: '\e80d'; }
.icon-health-wellness:before { content: '\e80e'; }
.icon-home-kitchen-bath:before { content: '\e80f'; }
.icon-hotels-accommodations:before { content: '\e810'; }
.icon-jewelry-accessories:before { content: '\e811'; }
.icon-magazines-newspapers:before { content: '\e812'; }
.icon-mens-clothing:before { content: '\e813'; }
.icon-office-school-supplies:before { content: '\e814'; }
.icon-shoes:before { content: '\e817'; }
.icon-shop:before { content: '\e818'; }
.icon-spa-treatments:before { content: '\e819'; }
.icon-sports-outdoors:before { content: '\e81a'; }
.icon-travel-luggage:before { content: '\e81c'; }
.icon-tools-auto-pets:before { content: '\e81d'; }
.icon-unselected:before { content: '\e81e'; }
.icon-wireless-services:before { content: '\e81f'; }
.icon-womens-clothing:before { content: '\e820'; }
