@import '~core/styles/mixins';
@import 'variables';

@mixin mn_linkWithArrow($fontSize: 1.2rem, $margin: 0 0 0 .8rem, $fontWeight: 600, $color: $brandRed3) {
  &:after {
    content: '\f105';
    font-family: 'fontello', serif;
    font-size: $fontSize;
    vertical-align: middle;
    display: inline-block;
    margin: $margin;
    color: $color;
    font-weight: $fontWeight;
  }
}

@mixin delta-header-line($deltaHeaderLinePadding) {
  &:after {
    content: '';
    display: block;
    width: 7.5rem;
    border-bottom: 2px solid $brandRed3;
    margin: 0;
    padding: $deltaHeaderLinePadding;
  }
}

@mixin mn_pipe($padding: 0 .3rem) {
  &:after {
    content: '|';
    padding: $padding;
  }
}
