[class*='mn_deltaSVGIcon'] {
  background: none;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;

  &:before {
    content: '';
    display: block;
    width: 2.8rem;
    height: 3.2rem;
  }
}

.mn_deltaSVGIconExtraMiles:before {
  background: url(../assets/images/icons/plane_icon.svg) no-repeat center/100%;
}

.mn_deltaSVGIconHamburger:before {
  background: url(../assets/images/icons/hamburger_menu_icon.svg) no-repeat no-repeat;
}

.mn_deltaSVGIconHelp:before {
  background: url(../assets/images/icons/help_icon.svg) no-repeat center/100%;
}

.mn_deltaSVGIconFavorite:before {
  background: url(../assets/images/icons/favorite_circle_icon.svg) no-repeat top/100%;
}

.mn_deltaSVGIconMyAccount:before {
  background: url(../assets/images/icons/user_icon.svg) no-repeat no-repeat;
}

.mn_deltaSVGIconSearch:before {
  background: url(../assets/images/icons/search_icon_white.svg) no-repeat bottom/100%;
}

.mn_deltaSVGIconSearchGrey:before {
  background: url(../assets/images/icons/search_icon_grey.svg) no-repeat right/75%;
  width: 3rem;
}

.mn_deltaSVGIconNewSearch:before {
  background: url(../assets/images/icons/new_search_icon.svg) no-repeat center/100%;
  width: 4rem;
}

.mn_deltaSVGIconClose:before {
  background: url(../assets/images/icons/close.svg) no-repeat no-repeat;
}

.mn_deltaIconExternalLinkGrey:after {
  content: '';
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  background: no-repeat center/100% url(../assets/images/icons/external_link_grey_icon.svg);
}
