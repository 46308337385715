@import '~org/styles/variables';

.mn_mobileNavWelcomeMessage {
  text-align: center;
  padding: 1.5rem 1rem;
  color: $brandWhite;
  position: relative;

  .mn_userInfoWrap {
    padding: .5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
  }

  .mn_accountLink {
    margin-left: 1.5rem;
    padding-left: 1.5rem;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 2px;
      background: $brandBlue5;
    }
  }

  .mn_button {
    display: block;
    max-width: 22rem;
    text-transform: none;
    padding: 0;
    margin: 0 auto;
    height: 4.8rem;
    line-height: 4.8rem;

    &.mn_logoutLink {
      margin: 1rem auto 0;
    }
  }

  p {
    margin: 0;
  }
}
