@import '~org/styles/variables';

.mn_navOffersTOF {
  a {
    position: relative;

    .mn_extraRewardsPill {
      width: 10rem;
      left: calc(100% + 1rem);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
