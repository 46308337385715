@import '~org/styles/variables';

.mn_holidayImage + .mn_headerMainBar {
  padding-top: 0;
}

.mn_pageHeader {
  background: $brandDarkerBlue;

  .mn_searchBar {
    position: relative;
    z-index: 1;
  }

  .mn_headerInner {
    position: relative;
    z-index: 101;
    transform: translateZ(0);
    transition: opacity .5s ease-in-out;
  }

  .mn_headerMainBar {
    position: relative;
    z-index: 3;
  }

  // Sticky
  &.mn_sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;
    transform: translateZ(0);

    .mn_headerInner {
      opacity: 1;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .4);
    }

    .mn_headerMainBar {
      transition: .2s padding ease-in-out, .2s background ease-in-out;

      .mn_brandLogo a {
        &:before,
        &:after {
          transition: all .5s ease-in-out;
        }
      }
    }
  }

  .mn_brandLinks {
    display: none;
    text-align: center;
    padding: 1.2rem 0 2.5rem;
  }

  .mn_button {
    margin-left: 1.5rem;
    padding-left: 1.5rem;
    position: relative;
    color: $brandWhite;
    font-weight: bold;
    font-size: 1.2rem;
    background: none;
  }

  .mn_loginLink,
  .mn_logoutLink {
    width: 10rem;
    height: 3.5rem;
    padding: 1rem;
    font-size: 1.3rem;
    font-weight: bold;
    color: $brandWhite;
    background: $brandRed3;
    border-radius: 4px;
    text-align: center;
    letter-spacing: .8px;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1;
  }

  @media #{$medium-screen} {
    .mn_signUpLink {
      background: $brandRed3;
      width: 10rem;
    }

    .mn_loginLink,
    .mn_logoutLink {
      background: none;
      width: 5rem;
      padding: 1rem 0;
    }
  }
}

.mn_pageHeaderOffset {
  overflow: hidden;
  visibility: hidden;
  flex: 0 0 0;
}

.mn_VHP {
  .mn_pageHeader.mn_isAnonymous {
    .mn_brandLinks {
      border-top: 1px solid $brandBlue5;
      margin: 1rem 1rem 0;
      padding: 2rem 0 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;

      @media #{$medium-screen} {
        border-top: none;
        margin: 0;
        padding: 0;
      }
    }

    &:not(.mn_sticky) {
      .mn_headerMainBar {
        background: none;
      }
    }
  }
}
