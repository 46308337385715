@import '~org/styles/variables';

.mn_brandLogo {
  display: block;
  height: 7rem;
  width: 25rem;
  margin: .5rem 2rem 0 0;

  a {
    display: block;
    height: 100%;
    overflow: hidden;
    text-indent: -200%;
    position: relative;
    font-size: 0;
    line-height: 0;

    &:after {
      content: '';
      position: absolute;
      top: .7rem;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('~org/assets/images/icons/logo.svg') no-repeat 0 0;
      background-size: 100%;
    }
  }
}
