@import '~org/styles/variables';

.mn_brandFooter {
  text-align: center;
  padding: 4rem 0;
  background-color: $brandDarkerBlue;
  color: $brandWhite;

  a {
    white-space: nowrap;
    color: $brandWhite;
  }

  ul {
    display: inline-block;
    border-top: 1px solid $brandBlue5;
    border-bottom: 1px solid $brandBlue5;
    width: 100%;
    font-size: 1.4rem;
    padding: 4rem 0;
    margin: 6rem 0 5rem 0;

    li {
      display: inline-block;
      padding: .9rem .9rem 0;
      letter-spacing: .34px;
    }
  }

  .mn_backToTopButton {
    margin: 3rem 0;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: .5px;
    background: none;
    border: none;
    color: $brandWhite;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @media #{$large-screen} {
    ul {
      font-size: 1.5rem;

      li {
        padding: 1rem 1.5rem 0;
      }
    }
  }
}

@media #{$less-than-medium-screen} {
  .mn_VHP .mn_brandFooter {
    display: none;
  }

  .mn_MHP .mn_brandFooter {
    background: #e6eaf2;
    padding-bottom: 4rem;
  }
}
