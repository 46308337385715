@import '~core/styles/mixins';
@import '~org/styles/variables';

.mn_accountNavigation {
  display: flex;
  justify-content: flex-start;
  color: $brandWhite;
  margin-left: 2rem;

  /* Account Navigation elements */
  .mn_navigationWrap {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    & > li:not(.mn_userSection) {
      padding: 0 .6rem;
    }

    .mn_navigationItem {
      display: block;
      padding: .1rem .6rem;
      margin-top: 1.3rem;
      text-decoration: none;
      color: $brandWhite;
      text-align: center;
      background: none;
      border: none;
      position: relative;

      & > i {
        display: block;
        width: 2rem;
        height: 2.2rem;
        line-height: 1.9rem;
        font-size: 2.8rem;
      }
    }

    .mn_searchTrigger {
      width: 2.8rem;
      height: 5rem;
      cursor: pointer;
      margin: 0 0 .7rem;
      padding: 0;
    }
  }

  /* Dropdown */
  .mn_navigationDropdownTrigger {
    position: relative;
    margin: 0 0 -2.5rem;
    padding: 0 0 2.1rem;
    display: flex;
    align-items: flex-end;

    &:hover,
    &:focus,
    &:active {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: -8px;
        bottom: 15px;
        height: 3px;
        background: $brandRed4;
      }
    }

    .mn_navigationItem {
      position: relative;
      z-index: 2;
      bottom: 1rem;
    }

    .mn_navigationDropdown {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(-25%, 28px, 0);
      transition: all .3s ease-in-out;
      position: absolute;
      top: 2.5rem;
      right: -14rem;
      width: 38rem;
      background: $brandWhite;
      padding: 0 0 3rem;
      border: 1px solid $brandLightGray2;
      z-index: 2;
      text-align: left;

      &.mn_extraRewardsNavigationDropdown {
        padding: 1rem 3rem 3rem;

        &:before {
          left: 87%;
        }

        .mn_sectionTitle {
          margin: 0 0 1rem;

          h2 {
            font-weight: 500;
            font-size: 2rem;
            margin: 1rem 0 0;
          }
        }

        .mn_linkBottom {
          font-weight: 600;
          margin: 1rem 0 0;
          color: $brandGray6;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin: 0;
          padding: 1.2rem 0;
          font-size: 1.4rem;
          position: relative;
          height: 5rem;

          &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            height: 1px;
            width: 90%;
            border-bottom: 1px solid $brandLightGray2;
            transform: translateX(-50%);
          }
        }

        a {
          color: $brandGray6;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          line-height: 5rem;
          padding: 0 3rem;

          &:hover {
            background: $brandLightGray2;
          }
        }

        .mn_deltaIconExternalLinkGrey:after {
          position: absolute;
          left: 10.5rem;
          top: 1.7rem;
        }
      }
    }

    &.mn_focused {
      .mn_navigationDropdown {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }
  }

  .mn_favoritesSection {
    .mn_navigationItem {
      margin: .1rem 0 0;

      &:hover {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: -.8rem;
          bottom: -1.4rem;
          height: 3px;
          background: $brandRed4;
        }
      }
    }
  }

  /* User section */
  .mn_userSection {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 .5rem 0 0;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 2px;
      background: $brandBlue5;
    }

    .mn_navigationDropdownTrigger {
      &:hover {
        &:after {
          display: none;
        }
      }
    }

    .mn_userInfo {
      text-align: right;
      font-size: 1.1rem;
      line-height: 1.6rem;
      letter-spacing: -.5px;
      position: relative;
      margin-right: .5rem;
      padding-right: 5.1rem;

      .mn_userNameWrap {
        display: block;
        white-space: nowrap;
        font-weight: 700;

        .mn_userName {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 17rem;
          vertical-align: bottom;
        }
      }

      .mn_userInfoWrap {
        font-weight: 700;
      }
    }

    .mn_navigationItem {
      display: block;
      margin-top: 1.3rem;
      text-decoration: none;
      color: $brandWhite;
      text-align: center;
      background: none;
      border: none;
      position: absolute;
      bottom: 1rem;
      right: .6rem;
      padding: 0 .6rem 2.1rem;

      & > i {
        width: 3rem;
      }

      &:hover,
      &:focus,
      &:active {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: -.3rem;
          bottom: .5rem;
          height: 3px;
          background: $brandRed4;
        }
      }
    }

    .mn_navigationDropdown {
      .mn_highlitedSection {
        padding: 1.2rem 1.7rem;
        background: $brandLightGray5;
        color: $brandBlack;

        .mn_userId {
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: .5rem;
        }

        .mn_lifetimeEarning {
          font-size: 1.2rem;
          margin: 0 0 .5rem;
        }
      }
    }

    .mn_button {
      height: 3.5rem;
      padding: .5rem 0;
      margin: 0 2rem;
    }
  }

  /* Extra Rewards Dropdown section */
  .mn_headerExtraRewardsSection {
    .mn_navigationDropdown {
      right: -10rem;
      width: 35.7rem;
    }
  }
}

/* Secondary navigation search bar for tablet and small desktop */
.mn_secondaryNavSearchBar {
  margin: 0 0 0 auto;

  .mn_searchBar {
    width: 25.4rem;
    padding: 0;

    &:before {
      display: none;
    }

    .mn_searchForm {
      .mn_dropDownWrap {
        left: -5rem;
        right: -5rem;
        top: 5.5rem;
        box-shadow: 0 2px 4px 0 $brandQuaternaryColor;
      }

      .mn_searchInput {
        background: 0 0;
        border-bottom: 1px solid $brandGray6;
        color: $brandWhite;
        font-size: 2rem;
        font-weight: 300;
        height: 4.5rem;
        border-radius: 0;

        &:focus {
          background: $brandBlue6;
          border: 2px solid $brandGray7;
          border-bottom: 2px solid $brandWhite;
          border-radius: 4px;
        }
      }

      .mn_searchSubmit {
        position: absolute;
        top: 0;
        right: .5rem;
        background: 0 0;
        border: none;
        height: 4.5rem;
        line-height: 4.5rem;
        font-size: 2.2rem;
        width: 3rem;
        padding: .2rem .5rem 0 0;
      }
    }
  }
}
