@import '~org/styles/variables';

.mn_navBar {
  .mn_mainNav {
    display: flex;
    flex-direction: column;
    user-select: none;
    margin-bottom: 1rem;

    &.mn_contentSection {
      padding: 1rem 2.5rem;
    }

    .mn_navigationListItem {
      border-bottom: 1px solid $brandBlue5;
      margin: 0;
    }
  }

  .mn_pill {
    width: 10rem;
    transform: none;
    left: calc(100% + 1rem);
    top: .2rem;
  }
}
