@import 'variables';
@import 'mixins';
@import 'interstitial';
@import 'icons';
@import '~core/styles/responsive-utilities';
@import '~core/styles/grid';
@import '~core/styles/mixins';
@import '~core/styles/defaults';

/* Fonts */
@import 'fonts';
@import '~core/fonts/fontello/css/fontello';
@import '~org/fonts/delta-categories/css/delta_categories';

html {
  width: 100%;

  &.mn_isIphoneSafari {
    overflow-y: initial;
  }

  &.mn_open {
    @media #{$less-than-medium-screen} {
      overflow-y: hidden;
    }
  }
}

.mn_noScroll {
  position: absolute;
  overflow: hidden;
  width: 100%;
}

.mn_pageWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}

.mn_trendingAndSeasonalOffers,
.mn_personalizedTopOffers,
.mn_featuredDeals,
.mn_earningsSnapshot {
  .mn_sectionTitle {
    @include delta-header-line(1rem 0 0);
  }
}

.mn_featuredDeals {
  @media #{$medium-screen} {
    .mn_sectionTitle:after {
      content: none;
    }
  }
}

.mn_onboarding,
.mn_onboardingCongrats {
  h2 {
    @include delta-header-line(1.5rem 0 0);

    &:after {
      margin: 0 auto;
    }
  }

  sup {
    line-height: 0;
  }

  @media #{$large-screen} {
    .mn_stepButton.mn_stepOptinDone.mn_button {
      margin-top: 1rem;
    }
  }
}

.mn_checkbox,
.mn_radio {
  label {
    position: relative;
    display: inline-block;
    padding-left: 3.5rem;

    &:before,
    &:after {
      position: absolute;
      content: '';
      display: inline-block;
    }

    &:before {
      background-color: $brandWhite;
      color: $brandWhite;
      height: 20px;
      width: 20px;
      border: 1px solid #ccc;
      border-radius: 3px;
      vertical-align: middle;
      left: 0;
    }

    &:after {
      height: 7px;
      width: 12px;
      border: 2px solid $brandWhite;
      border-top-style: none;
      border-right-style: none;
      margin: 5px;
      -ms-transform: rotate(-55deg);
      -webkit-transform: rotate(-55deg);
      transform: rotate(-55deg);
      left: -1px;
      top: 0;
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    opacity: 0;
    position: absolute;

    & + label:after {
      content: none;
    }

    &:checked + label:before {
      background-color: $brandRed3;
      border: 1px solid $brandRed3;
    }

    &:checked + label:after {
      content: '';
    }
  }
}

.mn_MHP {
  .mn_earningsSnapshot {
    .mn_sectionTitle {
      border-top: 1px solid #798292;
    }
  }

  .mn_newMembers {
    .mn_emailOptInWrapper {
      .mn_decorationLine {
        border-bottom: 1px solid rgba(186, 197, 210, .75);
        position: absolute;
        bottom: 0;
        width: 80%;
        left: calc(50% - 80% / 2);
      }
    }
  }

  .mn_similarStoreTile .mn_rebateWrap .mn_premierMerchantRebate {
    .mn_elevationOldValue {
      color: $brandWhite;
    }
  }

  @media #{$medium-screen} {
    .mn_flipTile {
      .mn_premierMerchantRebate .mn_rebateValueWithCurrency {
        .mn_elevationOldValue {
          color: $brandTextColor;
        }
      }
    }

    .mn_quickSearch .mn_merchantRebate {
      color: $brandGray6;

      .mn_rebateValueWithCurrency {
        .mn_rebateValue,
        .mn_rebateCurrency {
          color: $brandGray6;

          .mn_elevationOldValue {
            color: $brandGray6;
          }
        }

        .mn_rebateValue {
          @include rebate-elevation($brandTextColor, 1px solid $brandGray6);
        }
      }
    }

    .mn_quickSearchContent .mn_matchText {
      @include mn_pipe(0 .5rem);
    }
  }

  @media #{$less-than-medium-screen} {
    .mn_dropDownWrap .mn_recentStores .mn_flipTile {
      .mn_itemContent {
        border-bottom: none;
      }
    }
  }
}

html.mn_VHP {
  .mn_sectionTitle {
    align-items: center;

    h2 {
      font-size: 3.2rem;
      line-height: 4rem;
      font-weight: 400;
    }
  }

  .mn_howItWorks .mn_howItWorksVideoLink {
    @include mn_linkWithArrow(2.2rem, 0 0 .2rem .8rem, 400);
  }

  .mn_quickSearch {
    .mn_quickSearchContent {
      justify-content: flex-start;

      .mn_matchText {
        @include mn_pipe(0 .5rem);
      }
    }

    .mn_merchantRebate {
      color: $brandGray6;

      .mn_rebateValueWithCurrency {
        .mn_rebateValue,
        .mn_rebateCurrency {
          color: $brandGray6;

          .mn_elevationOldValue {
            color: $brandGray6;
          }
        }

        .mn_rebateValue {
          @include rebate-elevation($brandTextColor, 1px solid $brandGray6);
        }
      }
    }
  }

  @media #{$less-than-medium-screen} {
    .mn_searchBar {
      background: $brandDarkerBlue;

      .mn_searchForm {
        margin: 2rem 2rem 4rem;

        .mn_searchInput {
          font-size: 2.4rem;
          background: $brandBlue6;
          color: $brandWhite;
          border-radius: 4px;
          padding: .4rem 3.5rem .4rem 1.5rem;
          border: 2px solid $brandGray7;
          border-bottom: 2px solid $brandWhite;
        }

        .mn_dropDownWrap {
          margin: 4rem -3.5rem;
        }
      }
    }
  }

  .mn_merchantLogos .mn_list > * {
    @media (max-width: 400px) {
      flex-basis: calc((100% / 3) - 1rem + (1rem / 3));
    }
  }
}

.mn_navSeasonalFlyoutSection .mn_link,
.mn_navPopularStoresSection .mn_viewAllPopularStoresLink,
.mn_accountNavigation .mn_extraRewardsNavigationDropdown .mn_linkBottom,
.mn_navCategoryFeaturedSection .mn_seeAllLink {
  @include mn_linkWithArrow($fontSize: 1.7rem);
}

.mn_categoryFlyoutLink h3,
.mn_navCategoryFeaturedItem h3 {
  @include mn_pipe();
}

.mn_searchBar {
  .mn_quickSearch {
    .mn_quickSearchResults {
      border-width: 0;

      &.mn_noResult {
        display: flex;
        flex-direction: column;

        .mn_productLinkWrap {
          order: 2;
        }

        .mn_quickSearchResults {
          order: 1;
          border-width: 0;
        }
      }
    }
  }
}
